import React, { useCallback, useRef } from 'react';
import HelpMp4 from '../../assets/video/help-gen.mp4';
import Dialog from '../../components/dialog.jsx';
import style from './help.module.scss';

function HelpDialog({ className, visible, setVisible }) {
  const videoRef = useRef();
  const videoPause = useCallback(() => {
    if (!videoRef.current) return;
    videoRef.current.pause();
  }, [videoRef, videoRef.current]);

  const onVisibleChange = useCallback(
    (val) => {
      if (!val) videoPause();
      setVisible(val);
    },
    [setVisible, videoPause]
  );

  return (
    <Dialog
      className={className}
      // visible={visible}
      visible={true}
      setVisible={onVisibleChange}
      // title="Tutorial"
    >
      <div className={style.tips}>*System is currently pending Adobe Firefly licensing from UL core team.</div>
      <div className={style.box1}>
        <video ref={videoRef} className={style.video} src={HelpMp4} controls={true} />
      </div>
    </Dialog>
  );
}

export default HelpDialog;
