import { message } from 'antd';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './top.module.scss';

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const list = [
    { name: 'Main Dashboard', url: '/package-ai-inspiration' },
    // { name: 'Innovations Library (Mintel)' },
    // { name: 'Pack Evolution' },
    { name: 'Help & Resources', url: '/package-ai-inspiration/help' },
  ];

  const onNav = useCallback(
    (val) => {
      if (!val) {
        message.info('Coming soon');
        return;
      }
      navigate(val);
    },
    [navigate]
  );

  return (
    <div className={style.topBarWrap}>
      <h1 className={style.title}>Pack AI Inspiration</h1>
      <div className={style.navWrap}>
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(style.navItem, {
                [style.active]: location.pathname === item.url,
              })}
              onClick={() => {
                onNav(item.url);
              }}
            >
              <span>{item.name}</span>
              <s></s>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopBar;
