import React from 'react';
import { Select } from 'antd';
import Style from './brand-filter.module.scss';
import { arrowD } from '../../../../components/icons/icons.jsx';
const { Option } = Select;

const BrandSelect = ({ brands, selectedBrands, onChange, dropdownStyle }) => {
  const handleChange = (value) => {
    if (value.includes('selectAll')) {
      if (selectedBrands.length === brands.length) {
        onChange([]); // Deselect all
      } else {
        onChange(brands.map(brand => brand.name)); // Select all brands by their names
      }
    } else {
      onChange(value); // Update with the selected values
    }
  };

  return (
    <Select
      mode="multiple"
      placeholder="Brand"
      value={selectedBrands}
      popupMatchSelectWidth={false}
      onChange={handleChange}
      maxTagCount={1}
      maxTagTextLength={selectedBrands.length > 1 ? 2 : 8}
      className="custom-select-top-dish filter-select"
      dropdownStyle={dropdownStyle}
      suffixIcon={<img src={arrowD} />}
    >
      <Option value="Brand" disabled className="topdish-disabled-filter">
        Brand
      </Option>
      <Option key="selectAll" value="selectAll" className="topdish-filter">
        <input
          type="checkbox"
          className="topdish-filter-checkbox topdish-scroll"
          checked={selectedBrands.length === brands.length}
          readOnly
        />
        Select All
      </Option>
      {brands.map(brand => {
        return (
          <Option key={brand.name} value={brand.name} className="topdish-filter">
            <input
              type="checkbox"
              className="topdish-filter-checkbox"
              checked={selectedBrands.includes(brand.name)}
              readOnly
            />
            {brand.name}
          </Option>
        );
      })}
    </Select>
  );
};

export default BrandSelect;
