import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFilter } from '../../../api/index.js';
import { PackageAiCascader as Cascader } from '../../../components/cascader/index.jsx';
import { PackageAiSelect as Select } from '../../../components/select/index.jsx';
import style from './index.module.scss';

/**
 * build options
 * @param {string[]} options
 * @returns {Array<{label:string;value:string}>}
 */
function buildOptions(options) {
  return options.map((item) => ({
    label: item,
    value: item,
  }));
}

// eslint-disable-next-line no-unused-vars
function Filter({ className, query, setQuery }) {
  const [searchParams] = useSearchParams();

  const [marketOptions, setMarketOptions] = useState([]);
  const [marketData, setMarketData] = useState(
    searchParams.has('market') ? [searchParams.get('market')] : []
  );

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [verticalData, setVerticalData] = useState(
    searchParams.has('vertical') ? [searchParams.get('vertical')] : []
  );

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);

  const [companyOptions1, setCompanyOptions1] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const postVerticalData = useMemo(() => {
    if (
      marketData.length === 1 &&
      marketData[0] === 'US' &&
      verticalData.length === 0
    ) {
      return verticalOptions.map((e) => e.value);
    } else if (
      marketData.length === 1 &&
      marketData[0] === 'UK' &&
      verticalData.length === 0
    ) {
      return verticalOptions.map((e) => e.value);
    } else {
      return verticalData;
    }
  }, [verticalOptions, verticalData, marketData]);

  const postCompanyData = useMemo(() => {
    if (!companyData?.length) return [];
    else if (companyData.length === 1) {
      if (companyData[0] === 'Unilever') {
        return ['Unilever'];
      } else {
        return companyOptions1.filter((e) => e !== 'Unilever');
      }
    } else {
      if (companyData.includes('Unilever')) {
        return companyOptions1;
      } else {
        return companyOptions1.filter((e) => e !== 'Unilever');
      }
    }
  }, [companyData, companyOptions1]);

  const [brandOptions, setBrandOptions] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [packFormatOptions, setPackFormatOptions] = useState([]);
  const [packFormatData, setPackFormatData] = useState([]);

  const getMarketOptions = useCallback(async () => {
    const { data } = await getFilter('market');
    setMarketOptions(buildOptions(data));
  }, [setMarketOptions]);
  useEffect(() => {
    getMarketOptions().catch((err) => {
      console.error('get market options error', err);
    });
  }, [getMarketOptions]);

  const getVerticalOptions = useCallback(async () => {
    const { data } = await getFilter('vertical', { market: marketData });
    if (marketData.length === 1 && marketData[0] === 'US') {
      setVerticalOptions(
        buildOptions(
          data.filter(
            (e) => e !== 'Lifestyle Nutrition' && e !== 'Other Nutrition'
          )
        )
      );
    } else if (marketData.length === 1 && marketData[0] === 'UK') {
      setVerticalOptions(
        buildOptions(
          data.filter(
            (e) => e !== 'Lifestyle Nutrition' && e !== 'Other Nutrition'
          )
        )
      );
    } else {
      setVerticalOptions(buildOptions(data));
    }
  }, [marketData, setVerticalOptions]);
  useEffect(() => {
    getVerticalOptions().catch((err) => {
      console.error('get vertical options error', err);
    });
  }, [getVerticalOptions]);

  const getCategoryOptions = useCallback(async () => {
    const { data } = await getFilter('category', {
      market: marketData,
      vertical: postVerticalData,
    });
    setCategoryOptions(buildOptions(data));
  }, [marketData, postVerticalData, setCategoryOptions]);
  useEffect(() => {
    getCategoryOptions().catch((err) => {
      console.error('get category options error', err);
    });
  }, [getCategoryOptions]);

  const getSubCategoryOptions = useCallback(async () => {
    const { data } = await getFilter('sub_category', {
      market: marketData,
      vertical: postVerticalData,
      category: categoryData,
    });
    setSubCategoryOptions(buildOptions(data));
  }, [marketData, postVerticalData, categoryData, setSubCategoryOptions]);
  useEffect(() => {
    getSubCategoryOptions().catch((err) => {
      console.error('get sub category options error', err);
    });
  }, [getSubCategoryOptions]);

  const getCompanyOptions = useCallback(async () => {
    const { data } = await getFilter('company', {
      market: marketData,
      vertical: postVerticalData,
      category: categoryData,
      sub_category: subCategoryData,
    });
    setCompanyOptions1(data);

    if (data.length === 0) {
      setCompanyOptions([]);
    } else if (data.length === 1) {
      if (data[0] === 'Unilever') {
        setCompanyData([{ label: 'Unilever', value: 'Unilever' }]);
      } else {
        setCompanyOptions([{ label: 'Competitors', value: 'Competitors' }]);
      }
    } else {
      if (data.includes('Unilever')) {
        setCompanyOptions([
          { label: 'Unilever', value: 'Unilever' },
          { label: 'Competitors', value: 'Competitors' },
        ]);
      } else {
        setCompanyOptions([{ label: 'Competitors', value: 'Competitors' }]);
      }
    }
  }, [
    marketData,
    postVerticalData,
    categoryData,
    subCategoryData,
    setCompanyOptions,
  ]);
  useEffect(() => {
    getCompanyOptions().catch((err) => {
      console.error('get company options error', err);
    });
  }, [getCompanyOptions]);

  const getBrandOptions = useCallback(async () => {
    const { data } = await getFilter('brand', {
      market: marketData,
      vertical: postVerticalData,
      category: categoryData,
      sub_category: subCategoryData,
      company: postCompanyData,
    });
    const oo = data.reduce(
      (prev, cur) => {
        if (cur.includes('Private Label')) {
          prev[0].children.push({ label: cur, value: cur });
        } else {
          prev.push({ label: cur, value: cur });
        }
        return prev;
      },
      [{ label: 'Private Label', value: 'Private Label', children: [] }]
    );
    setBrandOptions(oo);
  }, [
    marketData,
    postVerticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    setBrandOptions,
  ]);
  useEffect(() => {
    getBrandOptions().catch((err) => {
      console.error('get brand options error', err);
    });
  }, [getBrandOptions]);

  const getPackformatOptions = useCallback(async () => {
    if (!brandData.length) {
      const { data } = await getFilter('physical_pack_format', {
        market: marketData,
        vertical: postVerticalData,
        category: categoryData,
        sub_category: subCategoryData,
        company: postCompanyData,
      });
      setPackFormatOptions(buildOptions(data));
    } else {
      const b = brandData.map((e) => e[e.length - 1]);
      const { data } = await getFilter('physical_pack_format', {
        market: marketData,
        vertical: postVerticalData,
        category: categoryData,
        sub_category: subCategoryData,
        company: postCompanyData,
        brand: b,
      });
      setPackFormatOptions(buildOptions(data));
    }
  }, [
    marketData,
    postVerticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    brandData,
    setPackFormatOptions,
  ]);
  useEffect(() => {
    getPackformatOptions().catch((err) => {
      console.error('get pack format options error', err);
    });
  }, [getPackformatOptions]);

  const onMarketChange = useCallback(
    (val) => {
      if (!val) {
        setMarketData([]);
      } else if (val instanceof Array) {
        setMarketData(val);
      } else {
        setMarketData([val]);
      }
      setVerticalData([]);
      setCategoryData([]);
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setMarketData,
      setVerticalData,
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onVerticalChange = useCallback(
    (val) => {
      if (!val) {
        setVerticalData([]);
      } else if (val instanceof Array) {
        setVerticalData(val);
      } else {
        setVerticalData([val]);
      }
      setCategoryData([]);
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setVerticalData,
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onCategoryChange = useCallback(
    (val) => {
      if (!val) {
        setCategoryData([]);
      } else if (val instanceof Array) {
        setCategoryData(val);
      } else {
        setCategoryData([val]);
      }
      setSubCategoryData([]);
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [
      setCategoryData,
      setSubCategoryData,
      setCompanyData,
      setBrandData,
      setPackFormatData,
    ]
  );

  const onSubCategoryChange = useCallback(
    (val) => {
      if (!val) {
        setSubCategoryData([]);
      } else if (val instanceof Array) {
        setSubCategoryData(val);
      } else {
        setSubCategoryData([val]);
      }
      setCompanyData([]);
      setBrandData([]);
      setPackFormatData([]);
    },
    [setSubCategoryData, setCompanyData, setBrandData, setPackFormatData]
  );

  const onCompanyChange = useCallback(
    (val) => {
      if (!val) {
        setCompanyData([]);
      } else if (val instanceof Array) {
        setCompanyData(val);
      } else {
        setCompanyData([val]);
      }
      setBrandData([]);
      setPackFormatData([]);
    },
    [setCompanyData, setBrandData, setPackFormatData]
  );

  const privateLabelValues = useMemo(() => {
    if (!brandOptions.length) return [];
    return brandOptions[0].children.map((e) => [
      brandOptions[0].value,
      e.value,
    ]);
  }, [brandOptions]);
  const onBrandChange = useCallback(
    (val) => {
      if (!val) {
        setBrandData([]);
      } else if (val instanceof Array) {
        const vv = [];
        for (const v of val) {
          if (v.length === 1 && v[0] === 'Private Label') {
            vv.push(...privateLabelValues);
          } else {
            vv.push(v);
          }
        }
        setBrandData(vv);
      } else {
        setBrandData([val]);
      }
      setPackFormatData([]);
    },
    [setBrandData, setPackFormatData, privateLabelValues]
  );
  const onPackFormatChange = useCallback(
    (val) => {
      if (!val) {
        setPackFormatData([]);
      } else if (val instanceof Array) {
        setPackFormatData(val);
      } else {
        setPackFormatData([val]);
      }
    },
    [setPackFormatData]
  );

  const postData = useMemo(() => {
    const d = {};
    if (marketData.length) d.market = marketData;
    if (postVerticalData.length) d.vertical = postVerticalData;
    if (categoryData.length) d.category = categoryData;
    if (subCategoryData.length) d.sub_category = subCategoryData;
    if (postCompanyData.length) d.company = postCompanyData;
    if (brandData.length) d.brand = brandData;
    if (packFormatData.length) d.physical_pack_format = packFormatData;
    return d;
  }, [
    marketData,
    postVerticalData,
    categoryData,
    subCategoryData,
    postCompanyData,
    brandData,
    packFormatData,
  ]);
  useEffect(() => {
    setQuery(postData);
  }, [postData]);

  const onClearData = useCallback(() => {
    onMarketChange([]);
  }, [onMarketChange]);

  return (
    <div className={classNames(style.box1, className)}>
      <div className={style.box11}>
        <div className={style.box111}>
          <div className={style.icon}></div>
          <div className={style.text}>Filter</div>
        </div>
        <div className={style.box112}>
          <div className={style.btn} onClick={onClearData}>
            Clear
          </div>
        </div>
      </div>
      <div className={style.box12}>
        <div className={style.label}>Market</div>
        <Select
          className={style.select}
          value={marketData}
          onChange={onMarketChange}
          options={marketOptions}
          allowClear={true}
        />
        <div className={style.label}>Vertical</div>
        <Select
          className={style.select}
          value={verticalData}
          onChange={onVerticalChange}
          options={verticalOptions}
          allowClear={true}
        />
        <div className={style.label}>Category</div>
        <Select
          className={style.select}
          value={categoryData}
          onChange={onCategoryChange}
          options={categoryOptions}
          allowClear={true}
        />
        <div className={style.label}>Sub Category</div>
        <Select
          className={style.select}
          value={subCategoryData}
          onChange={onSubCategoryChange}
          options={subCategoryOptions}
          allowClear={true}
        />
        <div className={style.label}>Company</div>
        <Select
          className={style.select}
          value={companyData}
          onChange={onCompanyChange}
          options={companyOptions}
          allowClear={true}
        />
        <div className={style.label}>Brand</div>
        <Cascader
          className={style.select}
          value={brandData}
          onChange={onBrandChange}
          options={brandOptions}
          mode="multiple"
          allowClear={true}
        />
        <div className={style.label}>Pack format</div>
        <Select
          className={style.select}
          value={packFormatData}
          onChange={onPackFormatChange}
          options={packFormatOptions}
          mode="multiple"
          allowClear={true}
        />
      </div>
    </div>
  );
}

export default Filter;
