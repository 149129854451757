import { useCallback, useMemo } from 'react';

function buildId({ market, sn }) {
  return `${market}-${sn}`;
}

export function useCategoryRank(originData) {
  const categoryRankData = useMemo(() => {
    const categoryRankMap = new Map();

    // loop all product
    for (const d of originData) {
      if (categoryRankMap.has(d.category)) {
        categoryRankMap.get(d.category).push(d);
      } else {
        categoryRankMap.set(d.category, [d]);
      }
    }

    const newRank = () => ({
      brand_logo_visibility: [],
      variant_name_visibility: [],
      intuitive_appeal: [],
      appeal: [],
      upi: [],

      taste: [],
      premium: [],
      natural: [],
      value: [],
      sustainable: [],
      modernity: [],
      excitement: [],
      desirability: [],
    });

    const sortRank = (list) => {
      const sl = list.sort((a, b) => b.value - a.value);
      const ll = list.length;

      const map = new Map();
      for (let i = 0; i < ll; i++) {
        map.set(buildId(sl[i]), i / ll);
      }

      return map;
    };

    for (const [key, value] of categoryRankMap) {
      const rank = newRank();
      for (const v of value) {
        const vv = { market: v.market, sn: v.sn };

        rank.brand_logo_visibility.push({
          ...vv,
          value: v.brand_logo_visibility,
        });
        rank.variant_name_visibility.push({
          ...vv,
          value: v.variant_name_visibility,
        });
        rank.intuitive_appeal.push({ ...vv, value: v.intuitive_appeal });
        rank.appeal.push({ ...vv, value: v.appeal });
        rank.upi.push({ ...vv, value: v.upi });

        rank.taste.push({ ...vv, value: v.taste });
        rank.premium.push({ ...vv, value: v.premium });
        rank.natural.push({ ...vv, value: v.natural });
        rank.value.push({ ...vv, value: v.value });
        rank.sustainable.push({ ...vv, value: v.sustainable });
        rank.modernity.push({ ...vv, value: v.modernity });
        rank.excitement.push({ ...vv, value: v.excitement });
        rank.desirability.push({ ...vv, value: v.desirability });
      }

      rank.brand_logo_visibility = sortRank(rank.brand_logo_visibility);
      rank.variant_name_visibility = sortRank(rank.variant_name_visibility);
      rank.intuitive_appeal = sortRank(rank.intuitive_appeal);
      rank.appeal = sortRank(rank.appeal);
      rank.upi = sortRank(rank.upi);

      rank.taste = sortRank(rank.taste);
      rank.premium = sortRank(rank.premium);
      rank.natural = sortRank(rank.natural);
      rank.value = sortRank(rank.value);
      rank.sustainable = sortRank(rank.sustainable);
      rank.modernity = sortRank(rank.modernity);
      rank.excitement = sortRank(rank.excitement);
      rank.desirability = sortRank(rank.desirability);

      categoryRankMap.set(key, rank);
    }

    return categoryRankMap;
  }, [originData]);

  const getCategoryRank = useCallback(
    (category, key, market, sn) => {
      if (!key) return 1;
      // console.log('category', dv);
      const vv = categoryRankData.get(category);
      // console.log('vrd', vv);
      if (!vv) return 1;
      const vvv = vv[key];
      // console.log('vvv', vvv);
      if (!vvv) return 1;
      const vvvv = vvv.get(buildId({ market, sn }));
      // console.log('vvvv', vvvv);
      return vvvv ?? 1;
    },
    [categoryRankData]
  );

  return {
    categoryRankData,
    getCategoryRank,
  };
}

export function useCategoryAvg(originData) {
  const categoryAvgData = useMemo(() => {
    const categoryAvgMap = new Map();

    // loop all product
    for (const d of originData) {
      if (categoryAvgMap.has(d.category)) {
        categoryAvgMap.get(d.category).push(d);
      } else {
        categoryAvgMap.set(d.category, [d]);
      }
    }

    const newAvg = () => ({
      brand_logo_visibility: 0,
      variant_name_visibility: 0,
      intuitive_appeal: 0,
      appeal: 0,
      upi: 0,

      taste: 0,
      premium: 0,
      natural: 0,
      value: 0,
      sustainable: 0,
      modernity: 0,
      excitement: 0,
      desirability: 0,
    });

    for (const [key, value] of categoryAvgMap) {
      const avg = newAvg();
      for (const v of value) {
        avg.brand_logo_visibility += v.brand_logo_visibility;
        avg.variant_name_visibility += v.variant_name_visibility;
        avg.intuitive_appeal += v.intuitive_appeal;
        avg.appeal += v.appeal;
        avg.upi += v.upi;

        avg.taste += v.taste;
        avg.premium += v.premium;
        avg.natural += v.natural;
        avg.value += v.value;
        avg.sustainable += v.sustainable;
        avg.modernity += v.modernity;
        avg.excitement += v.excitement;
        avg.desirability += v.desirability;
      }

      const ll = value.length;
      avg.brand_logo_visibility /= ll;
      avg.variant_name_visibility /= ll;
      avg.intuitive_appeal /= ll;
      avg.appeal /= ll;
      avg.upi /= ll;

      avg.taste /= ll;
      avg.premium /= ll;
      avg.natural /= ll;
      avg.value /= ll;
      avg.sustainable /= ll;
      avg.modernity /= ll;
      avg.excitement /= ll;
      avg.desirability /= ll;

      categoryAvgMap.set(key, avg);
    }

    return categoryAvgMap;
  }, [originData]);

  const getCategoryAvg = useCallback(
    (category, key) => {
      const vv = categoryAvgData.get(category);
      if (!vv) return 0;
      return vv[key];
    },
    [categoryAvgData]
  );

  return {
    categoryAvgData,
    getCategoryAvg,
  };
}
