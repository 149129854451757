import React from 'react';
import { Select } from 'antd';
import {arrowD} from '../../../../components/icons/icons.jsx';
const { Option } = Select;

const TopDishSelect = ({
  topDishes,
  selectedDishes,
  onChange,
  dropdownStyle,
}) => {
  const handleChange = (value) => {
    if (value.includes('selectAll')) {
      if (selectedDishes.length === topDishes.length) {
        onChange([]);
      } else {
        onChange(topDishes);
      }
    } else {
      onChange(value);
    }
  };
 
  return (
    <Select
      mode="multiple"
      placeholder="Top Dish"
      maxTagCount={1}
      maxTagTextLength={selectedDishes.length > 1 ? 2 : 18}
      value={selectedDishes}
      onChange={handleChange}
      className="custom-select-top-dish filter-select"
      dropdownStyle={dropdownStyle}
      popupMatchSelectWidth={true}
      suffixIcon={
        <img src={arrowD}/>
      }
    >
      <Option value="Top Dish" disabled className="topdish-disabled-filter">
        Top Dish
      </Option>
      <Option key="selectAll" value="selectAll" className="topdish-filter">
        <input
          type="checkbox"
          className="topdish-filter-checkbox topdish-scroll"
          checked={selectedDishes.length === topDishes.length}
          readOnly
        />
        Select All
      </Option>
      {topDishes.map((dish) => (
        <Option key={dish} value={dish} className="topdish-filter">
          <input
            type="checkbox"
            className="topdish-filter-checkbox topdish-scroll"
            checked={selectedDishes.includes(dish)}
            readOnly
          />
          {dish}
        </Option>
      ))}
    </Select>
  );
};

export default TopDishSelect;
