// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nqtmmaR6ZQ3T9iQOJpY2{padding:20px 10px 10px}.V41dvqrlcUqBcS83G9Dx{width:80vw;height:80vh;object-fit:contain}.kGOXNxS9w7FsEzV820v3{font-size:16px;font-weight:600;text-align:center;margin-top:30px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/base/help.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".box1 {\n  padding: 20px 10px 10px;\n}\n\n.video {\n  width: 80vw;\n  height: 80vh;\n  object-fit: contain;\n}\n.tips {\n  font-size: 16px;\n  font-weight: 600;\n  text-align: center;\n  margin-top: 30px;\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `nqtmmaR6ZQ3T9iQOJpY2`,
	"video": `V41dvqrlcUqBcS83G9Dx`,
	"tips": `kGOXNxS9w7FsEzV820v3`
};
export default ___CSS_LOADER_EXPORT___;
