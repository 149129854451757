import { App, ConfigProvider, theme } from 'antd';
import React from 'react';
import bgVideo from '../../../../assets/videos/trend-dial-video.mp4';
import Box from '../../components/box/index.jsx';
import TopBar from '../top-bar/top.jsx';
import style from './index.module.scss';

function PackageAiContainer({ children, loading }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        components: {
          Select: {
            optionFontSize: 13,
          },
          Button: {
            contentFontSize: 12,
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <div className={style.container}>
          <video
            className={style.bg}
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
          >
            <source src={bgVideo} />
          </video>
          <div className={style.content}>
            <TopBar />
            <Box className={style.body} loading={loading}>
              {children}
            </Box>
          </div>
        </div>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiContainer;
