import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Switch } from 'antd';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import exportToExcel from '../../api/export.js';
import { getProduct } from '../../api/index.js';
import { PackageAiBtn2 as Btn } from '../../components/btn/index.jsx';
import Container from '../../components/container/index.jsx';
import Divider from '../../components/divider/index.jsx';
import ImgDialog from '../../components/img-dialog/index.jsx';
import { useCategoryAvg, useCategoryRank } from '../../hooks/category.js';
import Chart from './chart/index.jsx';
import Filter from './filter/index.jsx';
import style from './index.module.scss';
import Table from './table/index.jsx';

function SwitchBtn({ className, value, setValue }) {
  const onTable = useCallback(() => {
    setValue('table');
  }, [setValue]);
  const onChart = useCallback(() => {
    setValue('chart');
  }, [setValue]);

  if (value === 'table') {
    return (
      <Btn className={className} onClick={onChart}>
        Switch to chart
      </Btn>
    );
  } else if (value === 'chart') {
    return (
      <Btn className={className} onClick={onTable}>
        Back to table
      </Btn>
    );
  } else {
    return <div className={className}>Unknown tab {value}</div>;
  }
}

function TabBody({
  className,
  value,
  data,
  setChartRef,
  showValue,
  onImgClick,
}) {
  if (value === 'table') {
    return (
      <Table
        className={className}
        data={data}
        setChartRef={setChartRef}
        showValue={showValue}
        onImgClick={onImgClick}
      />
    );
  } else if (value === 'chart') {
    return (
      <Chart
        className={className}
        data={data}
        setRef={setChartRef}
        showValue={showValue}
      />
    );
  } else {
    return <div className={className}>Unknown tab {value}</div>;
  }
}

function PackageAiView() {
  // loading
  const [loading, setLoading] = useState(false);

  // query
  const [query, setQuery] = useState(null);

  // data
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const getData = useCallback(async () => {
    if (!query) return;
    setLoading(true);
    try {
      // get category data
      const { data: cd } = await getProduct({ market: query.market ?? [] });
      setCategoryData(cd);
      // get data
      if (!query.brand) {
        const { data: dd } = await getProduct(query);
        setData(dd);
      } else {
        const q = { ...query };
        q.brand = query.brand.map((e) => {
          return e[e.length - 1];
        });
        const { data: dd } = await getProduct(q);
        setData(dd);
      }
    } catch (err) {
      console.error('get data error', err);
    }
    setLoading(false);
  }, [query, setData]);
  useEffect(() => {
    void getData();
  }, [getData]);

  const { getCategoryRank } = useCategoryRank(categoryData);
  const { getCategoryAvg } = useCategoryAvg(categoryData);

  // build data
  const data2 = useMemo(() => {
    let maxAppeal = 0;
    let maxIntuitiveAppeal = 0;

    const d = data.map((e) => {
      maxAppeal = Math.max(maxAppeal, e.appeal);
      maxIntuitiveAppeal = Math.max(maxIntuitiveAppeal, e.intuitive_appeal);

      const avgAppeal = getCategoryAvg(e.category, 'appeal');
      const avgIntuitiveAppeal = getCategoryAvg(e.category, 'intuitive_appeal');

      return {
        name: e.display_name,
        key: `${e.market}-${e.sn}`,
        market: e.market,
        sn: e.sn,
        cover: e.cover,
        appeal: e.appeal,
        appeal2: (e.appeal / avgAppeal) * 100.0,
        appealRank: getCategoryRank(e.category, 'appeal', e.market, e.sn),
        intuitiveAppeal: e.intuitive_appeal,
        intuitiveAppeal2: (e.intuitive_appeal / avgIntuitiveAppeal) * 100.0,
        intuitiveAppealRank: getCategoryRank(
          e.category,
          'intuitive_appeal',
          e.market,
          e.sn
        ),
      };
    });

    return d.map((e) => {
      return {
        ...e,
        appeal3: (e.appeal / maxAppeal) * 100.0,
        intuitiveAppeal3: (e.intuitiveAppeal / maxIntuitiveAppeal) * 100.0,
      };
    });
  }, [data, getCategoryRank]);

  const [showValue, setShowValue] = useState(true);

  const onSaveExcel = useCallback(() => {
    exportToExcel(
      'Pack AI Competitive Landscape',
      data2.map((e) => {
        return {
          Market: e.market,
          'Product Name': e.name,
          'Considered Appeal': e.appeal,
          'Intuitive Appeal': e.intuitiveAppeal,
        };
      })
    );
  }, [data2]);

  const [chartRef, setChartRef] = useState(null);
  const onSavePng = useCallback(() => {
    if (!chartRef) return;
    html2canvas(chartRef).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = img;
      a.download = 'Pack AI Competitive Landscape.png';
      a.click();
    });
  }, [chartRef]);

  const onMenu = useCallback(
    ({ key }) => {
      if (key === '2') {
        onSavePng();
      } else if (key === '3') {
        onSaveExcel();
      }
    },
    [onSaveExcel]
  );

  // switch tab
  const [tab, setTab] = useState('table');

  // image dialog
  const [imgDialogVisible, setImgDialogVisible] = useState(false);
  const [imgDialogSrc, setImgDialogSrc] = useState();
  const [imgDialogAlt, setImgDialogAlt] = useState();
  const onImgClick = useCallback(
    (src, alt) => {
      setImgDialogSrc(src);
      setImgDialogAlt(alt);
      setImgDialogVisible(true);
    },
    [setImgDialogSrc, setImgDialogAlt, setImgDialogVisible]
  );

  return (
    <Container loading={loading}>
      <div className={style.box1}>
        <div className={style.box11}>
          <div className={style.box111}>
            <SwitchBtn value={tab} setValue={setTab} />
          </div>
          <div className={style.box112}>
            <Filter query={query} setQuery={setQuery} />
          </div>
        </div>
        <Divider className={style.box12} />
        <TabBody
          className={style.box13}
          value={tab}
          data={data2}
          setChartRef={setChartRef}
          showValue={showValue}
          onImgClick={onImgClick}
        />
      </div>
      <div className={style.box2}>
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <span className={style.menu}>
                    <span className={style.text}>Show Data Labels</span>
                    <Switch
                      className={style.switch}
                      checked={showValue}
                      onChange={(checked) => setShowValue(checked)}
                    />
                  </span>
                ),
              },
              {
                key: '2',
                label: 'Save as PNG',
              },
              {
                key: '3',
                label: 'Export as Excel',
              },
            ],
            onClick: onMenu,
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <MoreOutlined className={style.icon} />
        </Dropdown>
      </div>
      <ImgDialog
        visible={imgDialogVisible}
        setVisible={setImgDialogVisible}
        src={imgDialogSrc}
        alt={imgDialogAlt}
      />
    </Container>
  );
}

export default PackageAiView;
