// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UGm6gKZmUOy1wEl5Gnee{padding:16px 24px;box-sizing:border-box;display:flex;justify-content:space-between;align-items:center;background-color:#013b02}.UGm6gKZmUOy1wEl5Gnee .okHJLBl9dtF06nldW5KL{font-size:16px;font-weight:900;color:#3adf53}.UGm6gKZmUOy1wEl5Gnee .uPpuuNecajJ7AkOGXQLi .PhFpSJLKPLclWISDGUCQ{font-size:14px;font-weight:600;color:#fff;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/top-bar/top.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,wBAAA,CAEA,4CACE,cAAA,CACA,eAAA,CACA,aAAA,CAIA,kEACE,cAAA,CACA,eAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".topBarWrap {\n  padding: 16px 24px;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #013b02;\n\n  .title {\n    font-size: 16px;\n    font-weight: 900;\n    color: #3adf53;\n  }\n\n  .navWrap {\n    .navItem {\n      font-size: 14px;\n      font-weight: 600;\n      color: #fff;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBarWrap": `UGm6gKZmUOy1wEl5Gnee`,
	"title": `okHJLBl9dtF06nldW5KL`,
	"navWrap": `uPpuuNecajJ7AkOGXQLi`,
	"navItem": `PhFpSJLKPLclWISDGUCQ`
};
export default ___CSS_LOADER_EXPORT___;
