import React from 'react';
import Dialog from '../dialog/index.jsx';
import style from './index.module.scss';

function PackageAiImgDialog({ className = '', visible, setVisible, src, alt }) {
  return (
    <Dialog
      className={`${style.imgDialog} ${className}`}
      visible={visible}
      setVisible={setVisible}
    >
      <div className={style.imgDialogBox}>
        <div className={style.imgDialogBoxInner}>
          <img className={style.imgDialogImg} src={src} alt={alt} />
        </div>
      </div>
    </Dialog>
  );
}

export default PackageAiImgDialog;
