// Country
export const OptionsCountry = [
  {
    label: 'United Kingdom',
    value: 'UK',
  },
  {
    label: 'Germany',
    value: 'GER',
  },
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'France',
    value: 'FR'
  },
  {
    label: 'India',
    value: 'IND'
  },
  {
    label: 'Mexico',
    value: 'MX'
  }
];

const formatOptionsMap = {
  'Boullion': [
    {
      label: 'Liquid stocks and fonds',
      value: 'Liquid stocks and fonds',
    },
    {
      label: 'Stock cubes',
      value: 'Stock cubes',
    },
    {
      label: 'Powders',
      value: 'Powders',
    },
    {
      label: 'Granules',
      value: 'Granules',
    },
    {
      label: 'Jelly',
      value: 'Jelly',
    }
  ],
  'Dressings': [
    {
      label: 'Jar',
      value: 'Jar',
    },
    {
      label: 'Squeeze',
      value: 'Squeeze',
    },
    {
      label: 'Doy',
      value: 'Doy',
    },
    {
      label: 'Tube',
      value: 'Tube',
    },
    {
      label: 'Bottle',
      value: 'Bottle',
    },
    {
      label: 'Others',
      value: 'Others',
    }
  ],
  'Meal Solutions': [
    {
      label: 'Liquid Recipe Sauces',
      value: 'Liquid Recipe Sauces',
    },
    {
      label: 'Dry Recipe Sauces',
      value: 'Dry Recipe Sauces',
    },
    {
      label: 'Food Kits',
      value: 'Food Kits',
    }
  ],
  'Mini Meal': [
    {
      label: 'Pasta',
      value: 'Pasta',
    },
    {
      label: 'Rice',
      value: 'Rice',
    },
    {
      label: 'Rice Porridge',
      value: 'Rice Porridge',
    },
    {
      label: 'Noodle - Cup',
      value: 'Noodle - Cup',
    },
    {
      label: 'Noodle - Block',
      value: 'Noodle - Block',
    },
    {
      label: 'Others',
      value: 'Others',
    }
  ],
  'Plant Based': [
    {
      label: 'Centre of the plate',
      value: 'Centre of the plate',
    },
    {
      label: 'Ingredients',
      value: 'Ingredients',
    },
    {
      label: 'Others',
      value: 'Others',
    }
  ],
  'Others': [
    {
      label: 'n/a',
      value: 'n/a',
    }
  ]
};

export const AllOptionsTree = [
  {
    label: 'United Kingdom',
    value: 'UK',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        childrenList: {
          format: formatOptionsMap['Boullion'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        childrenList: {
          format: formatOptionsMap['Dressings'],
          brand: [
            {
              label: 'Hellmann\'s',
              value: 'Hellmann\'s',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Maille',
              value: 'Maille',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        childrenList: {
          format: formatOptionsMap['Meal Solutions'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        childrenList: {
          format: formatOptionsMap['Mini Meal'],
          brand: [
            {
              label: 'Pot Noodle',
              value: 'Pot Noodle',
            },
            {
              label: 'Unox',
              value: 'Unox',
            },
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        childrenList: {
          format: formatOptionsMap['Plant Based'],
          brand: [
            {
              label: 'Vegetarian butcher',
              value: 'Vegetarian butcher',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Others',
        value: 'Others',
        childrenList: {
          format: formatOptionsMap['Others'],
          brand: [
            {
              label: 'Marmite',
              value: 'Marmite',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Bovril',
              value: 'Bovril',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
  {
    label: 'United States',
    value: 'US',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        childrenList: {
          format: formatOptionsMap['Boullion'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        childrenList: {
          format: formatOptionsMap['Dressings'],
          brand: [
            {
              label: 'Hellmann\'s',
              value: 'Hellmann\'s',
            },
            {
              label: 'Sir Kensingtons',
              value: 'Sir Kensingtons',
            },
            {
              label: 'Maille',
              value: 'Maille',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Best Foods',
              value: 'Best Foods',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        childrenList: {
          format: formatOptionsMap['Meal Solutions'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        childrenList: {
          format: formatOptionsMap['Mini Meal'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        childrenList: {
          format: formatOptionsMap['Plant Based'],
          brand: [
            {
              label: 'Vegetarian butcher',
              value: 'Vegetarian butcher',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Others',
        value: 'Others',
        childrenList: {
          format: formatOptionsMap['Others'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Colman\'s',
              value: 'Colman\'s',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
  {
    label: 'Germany',
    value: 'GER',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        childrenList: {
          format: formatOptionsMap['Boullion'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        childrenList: {
          format: formatOptionsMap['Dressings'],
          brand: [
            {
              label: 'Hellmann\'s',
              value: 'Hellmann\'s',
            },
            {
              label: 'Calve',
              value: 'Calve',
            },
            {
              label: 'Kuner',
              value: 'Kuner',
            },
            {
              label: 'Chirat',
              value: 'Chirat',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        childrenList: {
          format: formatOptionsMap['Meal Solutions'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        childrenList: {
          format: formatOptionsMap['Mini Meal'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Pfanni',
              value: 'Pfanni',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        childrenList: {
          format: formatOptionsMap['Plant Based'],
          brand: [
            {
              label: 'Vegetarian butcher',
              value: 'Vegetarian butcher',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Others',
        value: 'Others',
        childrenList: {
          format: formatOptionsMap['Others'],
          brand: [
            {
              label: 'Maizena',
              value: 'Maizena',
            },
            {
              label: 'Chirat',
              value: 'Chirat',
            },
            {
              label: 'Mondamin',
              value: 'Mondamin',
            },
            {
              label: 'Pfanni',
              value: 'Pfanni',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
  {
    label: 'France',
    value: 'FR',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        childrenList: {
          format: formatOptionsMap['Boullion'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        childrenList: {
          format: formatOptionsMap['Dressings'],
          brand: [
            {
              label: 'Maille',
              value: 'Maille',
            },
            {
              label: 'Tabasco',
              value: 'Tabasco',
            },
            {
              label: 'Amora',
              value: 'Amora',
            },
            {
              label: 'Calve',
              value: 'Calve',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        childrenList: {
          format: formatOptionsMap['Meal Solutions'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        childrenList: {
          format: formatOptionsMap['Mini Meal'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Unox',
              value: 'Unox',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        childrenList: {
          format: formatOptionsMap['Plant Based'],
          brand: [
            {
              label: 'Vegetarian butcher',
              value: 'Vegetarian butcher',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Others',
        value: 'Others',
        childrenList: {
          format: formatOptionsMap['Others'],
          brand: [
            {
              label: 'Maizena',
              value: 'Maizena',
            },
            {
              label: 'Amora',
              value: 'Amora',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
  {
    label: 'India',
    value: 'IND',
    children: [
      {
        label: 'Coffee & Tea',
        value: 'Coffee & Tea',
        childrenList: {
          format: [
            {
              label: 'Beverages',
              value: 'Beverages',
            }
          ],
          brand: [
            {
              label: 'Brooke Bond',
              value: 'Brooke Bond',
            },
            {
              label: 'Bru',
              value: 'Bru',
            },
            {
              label: 'Lipton',
              value: 'Lipton',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Lifestyle Nutrition',
        value: 'Lifestyle Nutrition',
        childrenList: {
          format: [
            {
              label: 'Beverages',
              value: 'Beverages',
            }
          ],
          brand: [
            {
              label: 'Horlicks',
              value: 'Horlicks',
            },
            {
              label: 'Boost',
              value: 'Boost',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
  {
    label: 'Mexico',
    value: 'MX',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        childrenList: {
          format: formatOptionsMap['Boullion'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        childrenList: {
          format: formatOptionsMap['Dressings'],
          brand: [
            {
              label: 'Hellmann\'s',
              value: 'Hellmann\'s',
            },
            {
              label: 'Becel',
              value: 'Becel',
            },
            {
              label: 'Bertolli',
              value: 'Bertolli',
            },
            {
              label: 'Capullo',
              value: 'Capullo',
            },
            {
              label: 'Iberia',
              value: 'Iberia',
            },
            {
              label: 'Mantequilla',
              value: 'Mantequilla',
            },
            {
              label: 'Primavera',
              value: 'Primavera',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        childrenList: {
          format: formatOptionsMap['Meal Solutions'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Maizena',
              value: 'Maizena',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        childrenList: {
          format: formatOptionsMap['Mini Meal'],
          brand: [
            {
              label: 'Knorr',
              value: 'Knorr',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        childrenList: {
          format: formatOptionsMap['Plant Based'],
          brand: [
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      },
      {
        label: 'Others',
        value: 'Others',
        childrenList: {
          format: formatOptionsMap['Others'],
          brand: [
            {
              label: 'Becel',
              value: 'Becel',
            },
            {
              label: 'Ades',
              value: 'Ades',
            },
            {
              label: 'Lipton',
              value: 'Lipton',
            },
            {
              label: 'Carte d’Or',
              value: 'Carte d’Or',
            },
            {
              label: 'Cornetto',
              value: 'Cornetto',
            },
            {
              label: 'Holanda',
              value: 'Holanda',
            },
            {
              label: 'Magnum',
              value: 'Magnum',
            },
            {
              label: 'Others',
              value: 'Others',
            }
          ]
        }
      }
    ]
  },
];

// Category 已废弃
export const OptionsCategory = [
  {
    label: 'Boullion',
    value: 'Boullion',
  },
  {
    label: 'Dressings',
    value: 'Dressings',
  },
  {
    label: 'Meal Solutions',
    value: 'Meal Solutions',
  },
  {
    label: 'Mini Meal',
    value: 'Mini Meal',
  },
  {
    label: 'Plant Based',
    value: 'Plant Based',
  },
  {
    label: 'Others',
    value: 'Others',
  }
];

// CVM
export const OptionsCVM = [
  {
    label: 'Outstanding',
    value: '2',
  },
  {
    label: 'Ready',
    value: '1',
  },
  {
    label: 'Risky',
    value: '0',
  }
];

// Incrementality Checklist
export const OptionsIncCheckList = [
  {
    label: 'New group of users',
    value: 0,
  },
  {
    label: 'New trend',
    value: 1,
  },
  {
    label: 'New occasion',
    value: 2,
  },
  {
    label: 'New channel',
    value: 3,
  },
  {
    label: 'Truly new and differentiated benefit',
    value: 4,
  }
];

// API Pricetier
export const OptionsApiPriceTier = [
  {
    label: 'Masstige',
    value: 'Masstige',
  },
  {
    label: 'Super Premium',
    value: 'Super Premium',
  },
  {
    label: 'Premium',
    value: 'Premium',
  },
  {
    label: 'Upper Mainstream',
    value: 'Upper Mainstream',
  },
  {
    label: 'Lower Mainstream',
    value: 'Lower Mainstream',
  },
  {
    label: 'Value Segment',
    value: 'Value Segment',
  }
];

// Brand 已废弃
export const OptionsBrand = [
  {
    label: 'United Kingdom',
    value: 'UK',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Maille',
            value: 'Maille',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Pot Noodle',
            value: 'Pot Noodle',
          },
          {
            label: 'Unox',
            value: 'Unox',
          },
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Vegetarian butcher',
            value: 'Vegetarian butcher',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Marmite',
            value: 'Marmite',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Bovril',
            value: 'Bovril',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'United States',
    value: 'US',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Sir Kensingtons',
            value: 'Sir Kensingtons',
          },
          {
            label: 'Maille',
            value: 'Maille',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Best Foods',
            value: 'Best Foods',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Vegetarian butcher',
            value: 'Vegetarian butcher',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Colman\'s',
            value: 'Colman\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'Germany',
    value: 'GER',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Calve',
            value: 'Calve',
          },
          {
            label: 'Kuner',
            value: 'Kuner',
          },
          {
            label: 'Chirat',
            value: 'Chirat',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Pfanni',
            value: 'Pfanni',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Vegetarian butcher',
            value: 'Vegetarian butcher',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Maizena',
            value: 'Maizena',
          },
          {
            label: 'Chirat',
            value: 'Chirat',
          },
          {
            label: 'Mondamin',
            value: 'Mondamin',
          },
          {
            label: 'Pfanni',
            value: 'Pfanni',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'France',
    value: 'FR',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Maille',
            value: 'Maille',
          },
          {
            label: 'Tabasco',
            value: 'Tabasco',
          },
          {
            label: 'Amora',
            value: 'Amora',
          },
          {
            label: 'Calve',
            value: 'Calve',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Unox',
            value: 'Unox',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Vegetarian butcher',
            value: 'Vegetarian butcher',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Maizena',
            value: 'Maizena',
          },
          {
            label: 'Amora',
            value: 'Amora',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'Mexico',
    value: 'MEX',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Best Foods',
            value: 'Best Foods',
          },
          {
            label: 'Lizano',
            value: 'Lizano',
          },
          {
            label: 'Natura\'s',
            value: 'Natura\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Unox',
            value: 'Unox',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'n/a',
            value: 'n/a',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Continental',
            value: 'Continental',
          },
          {
            label: 'Maizena',
            value: 'Maizena',
          },
          {
            label: 'Unox',
            value: 'Unox',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'Brazil',
    value: 'BRA',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label:'Arisco',
            value:'Arisco',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Maizena',
            value: 'Maizena',
          },
          {
            label: 'Mae terra',
            value: 'Mae terra',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'Indonesia',
    value: 'IDN',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label:'Royco',
            value:'Royco',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Vegetarian butcher',
            value: 'Vegetarian buther',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Bango',
            value: 'Bango',
          },
          {
            label: 'Buavita',
            value: 'Buavita',
          },
          {
            label: 'JAWARA',
            value: 'JAWARA',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'India',
    value: 'IND',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Kissan',
            value: 'Kissan',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'n/a',
            value: 'n/a',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Boost',
            value: 'Boost',
          },
          {
            label: 'Horlicks',
            value: 'Horlicks',
          },
          {
            label: 'Horlicks Diabetes Plus',
            value: 'Horlicks Diabetes Plus',
          },
          {
            label: 'Horlicks Lite',
            value: 'Horlicks Lite',
          },
          {
            label: 'Horlicks Mother\'s Plus',
            value: 'Horlicks Mother\'s Plus',
          },
          {
            label: 'Horlicks Nutri Gummies',
            value: 'Horlicks Nutri Gummies',
          },
          {
            label: 'Horlicks Protein Plus',
            value: 'Horlicks Protein Plus',
          },
          {
            label: 'Horlicks Women\'s Plus',
            value: 'Horlicks Women\'s Plus',
          },
          {
            label: 'Kissan',
            value: 'Kissan',
          },
          {
            label: 'Others',
            value: 'Others',
          }

        ]
      }
    ]
  },
  {
    label: 'Philippines',
    value: 'PHL',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Lady\'s Choice',
            value: 'Lady\'s Choice',
          },
          {
            label: 'Best Foods',
            value: 'Best Foods',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
  {
    label: 'South Africa',
    value: 'ZAF',
    children: [
      {
        label: 'Boullion',
        value: 'Boullion',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Knorrox',
            value: 'Knorrox',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Dressings',
        value: 'Dressings',
        children: [
          {
            label: 'Hellmann\'s',
            value: 'Hellmann\'s',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Meal Solutions',
        value: 'Meal Solutions',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Rajah',
            value: 'Rajah',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Mini Meal',
        value: 'Mini Meal',
        children: [
          {
            label: 'Knorr',
            value: 'Knorr',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Plant Based',
        value: 'Plant Based',
        children: [
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      },
      {
        label: 'Others',
        value: 'Others',
        children: [
          {
            label: 'Robertsons',
            value: 'Robertsons',
          },
          {
            label: 'Others',
            value: 'Others',
          }
        ]
      }
    ]
  },
];

// Format 已废弃
export const OptionsFormat = [
  {
    label: 'Boullion',
    value: 'Boullion',
    children: [
      {
        label: 'Liquid stocks and fonds',
        value: 'Liquid stocks and fonds',
      },
      {
        label: 'Stock cubes',
        value: 'Stock cubes',
      },
      {
        label: 'Powders',
        value: 'Powders',
      },
      {
        label: 'Granules',
        value: 'Granules',
      },
      {
        label: 'Jelly',
        value: 'Jelly',
      }
    ]
  },
  {
    label: 'Dressings',
    value: 'Dressings',
    children: [
      {
        label: 'Jar',
        value: 'Jar',
      },
      {
        label: 'Squeeze',
        value: 'Squeeze',
      },
      {
        label: 'Doy',
        value: 'Doy',
      },
      {
        label: 'Tube',
        value: 'Tube',
      },
      {
        label: 'Bottle',
        value: 'Bottle',
      },
      {
        label: 'Others',
        value: 'Others',
      }
    ]
  },
  {
    label: 'Meal Solutions',
    value: 'Meal Solutions',
    children: [
      {
        label: 'Liquid Recipe Sauces',
        value: 'Liquid Recipe Sauces',
      },
      {
        label: 'Dry Recipe Sauces',
        value: 'Dry Recipe Sauces',
      },
      {
        label: 'Food Kits',
        value: 'Food Kits',
      }
    ]
  },
  {
    label: 'Mini Meal',
    value: 'Mini Meal',
    children: [
      {
        label: 'Pasta',
        value: 'Pasta',
      },
      {
        label: 'Rice',
        value: 'Rice',
      },
      {
        label: 'Rice Porridge',
        value: 'Rice Porridge',
      },
      {
        label: 'Noodle - Cup',
        value: 'Noodle - Cup',
      },
      {
        label: 'Noodle - Block',
        value: 'Noodle - Block',
      },
      {
        label: 'Others',
        value: 'Others',
      }
    ]
  },
  {
    label: 'Plant Based',
    value: 'Plant Based',
    children: [
      {
        label: 'Centre of the plate',
        value: 'Centre of the plate',
      },
      {
        label: 'Ingredients',
        value: 'Ingredients',
      },
      {
        label: 'Others',
        value: 'Others',
      }
    ]
  },
  {
    label: 'Others',
    value: 'Others',
    children: [
      {
        label: 'n/a',
        value: 'n/a',
      }
    ]
  }
];

const tableData = [
  {
    id: 2,
    name: 'Distinct Proposition',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 4,
    name: 'Attention Catching',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 5,
    name: 'Message Connection',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 6,
    name: 'Clear, Concise Message',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 1,
    name: 'Need/Desire',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 3,
    name: 'Advantage',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 7,
    name: 'Credibility',
    data: {
      type: '0',
      content: ''
    }
  },
  {
    id: 8,
    name: 'Acceptable Costs',
    data: {
      type: '0',
      content: ''
    }
  }
];

// result {}
// 'Acceptable Costs': 1,
// 'Advantage': 1,
// 'Attention Catching': 1,
// 'CVM': 1,
// 'Clear, Concise Message': 1,
// 'Credibility': 1,
// 'Distinct Proposition': 1,
// 'Message Connection': 1,
// 'Need/Desire': 1

export const resultFormat = (result) => {
  const res = {
    cvm: {
      type: result?.CVM
    },
    tableData: []
  };
  tableData.forEach(item => {
    item.data.type = result[item.name];
    res.tableData.push({
      ...item,
      data: {
        type: result[item.name],
        content: ''
      }
    });
  });
  return res;
};
