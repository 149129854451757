import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Divider from '../../../components/divider/index.jsx';
import Chart from './chart.jsx';
import Header from './header.jsx';
import style from './index.module.scss';

function Table({ className, data, setChartRef, showValue, onImgClick }) {
  // sort, appeal, intuitiveAppeal
  const [sortBy, setSortBy] = useState('appeal');
  const [sortOrder, setSortOrder] = useState('desc');

  const sortData = useMemo(() => {
    return data.sort((a, b) => {
      let x = a[sortBy];
      let y = b[sortBy];
      if (sortOrder === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }, [data, sortBy, sortOrder]);

  return (
    <div className={classNames(style.box13, className)}>
      <div className={style.box131}>
        <div className={classNames(style.item, style.item1)}>
          <Header
            text="Considered Appeal"
            tip="This metric measures the percentage of respondents who found the packaging appealing within a sub-category shelf context. It is derived from consumer survey data."
            sort={sortBy === 'appeal' ? sortOrder : undefined}
            onSortClick={() => {
              setSortBy('appeal');
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }}
          />
          <div
            className={classNames(style.itemBox1, {
              [style.itemBox1Active]: sortBy === 'appeal',
            })}
          />
        </div>
        <Divider />
        <div className={style.item}>
          <Header
            text="Intuitive Appeal"
            tip="This metric measures the percentage of respondents who found the package design appealing in isolation. It is derived from an Implicit Reaction  Test by consumers"
            sort={sortBy === 'intuitiveAppeal' ? sortOrder : undefined}
            onSortClick={() => {
              setSortBy('intuitiveAppeal');
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }}
          />
          <div
            className={classNames(style.itemBox1, {
              [style.itemBox1Active]: sortBy === 'intuitiveAppeal',
            })}
          ></div>
        </div>
      </div>
      <div className={style.box132}>
        <Chart
          setRef={setChartRef}
          className={style.box1321}
          data={sortData}
          showValue={showValue}
          onImageClick={onImgClick}
        />
      </div>
    </div>
  );
}

export default Table;
