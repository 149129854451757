import React, { useState, useEffect } from 'react';
import LineCharts from '../../../trends-dial/components/line-charts/line-charts.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { noDataGraph } from '../../../../components/icons/icons.jsx';

const GraphComponent = ({
  selectedYears,
  selectedTimeRange,
  graphId,
  graphData,
  getMultiEchartsOption
}) => {
  const [monthLineOption, setMonthLineOption] = useState({});
  const [allgraphData, setAllGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);
  const [multiData,setMultiData] = useState(graphData);

  useEffect(() => {
    setLoading(true);
    if (graphData && graphData.GoogleSearches.monthsData && graphData.GoogleSearches.monthsData.length > 0) {
      const categoryData = graphData.GoogleSearches;
      setAllGraphData(categoryData.monthsData);
      setLoading(false);
      setNoDataMessage(false); // data is present, so no need to show the no data message
    } else {
      console.error(`Data for graphId ${graphId} not found or incomplete`);
      setLoading(false);
      setNoDataMessage(true); // no data found, so show the no data message
    }
  }, [graphData, graphId]);

  useEffect(() => {
    if (allgraphData.length > 0) {
      const sortedSelectedYears = selectedYears.slice().sort();
      const filteredData = sortedSelectedYears.flatMap((year) => {
        const yearString = year.slice(-2);
        return allgraphData.filter((item) => item.period.endsWith(yearString));
      });

      const sortedData = filteredData.sort((a, b) => {
        const [monthA, yearA] = a.period.split('-');
        const [monthB, yearB] = b.period.split('-');
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        ];
        const monthIndexA = monthNames.indexOf(monthA);
        const monthIndexB = monthNames.indexOf(monthB);
        if (yearA === yearB) {
          return monthIndexA - monthIndexB;
        } else {
          return parseInt(yearA) - parseInt(yearB);
        }
      });

      let dataToDisplay = [];

      switch (selectedTimeRange) {
      case 'Monthly':
        sortedSelectedYears.forEach((year) => {
          const yearData = sortedData.filter((item) =>
            item.period.endsWith(year.slice(-2))
          );
          dataToDisplay = dataToDisplay.concat(yearData.slice(0, 12));
        });
        break;
      case 'Quarterly':
        dataToDisplay = sortedData.filter((_, index) => index % 3 === 0);
        break;
      case 'Yearly':
      default:
        dataToDisplay = sortedSelectedYears.map((year) => {
          const yearString = year.slice(-2);
          const yearlyData = sortedData.filter((item) =>
            item.period.endsWith(yearString)
          );
          const totalValue = yearlyData.reduce(
            (sum, item) => sum + item.value,
            0
          );
          return { period: year, value: totalValue };
        });
        break;
      }

      monthOptionFn(dataToDisplay,multiData);
      setNoDataMessage(false); // data is present, so no need to show the no data message
    } else {
      setNoDataMessage(true); // no data found, so show the no data message
    }
  }, [allgraphData, selectedTimeRange, selectedYears, graphId]);

  const monthOptionFn = (data,multiData) => {
    const dateRange = data.map(e => {return e.period;});
    const googleData = multiData.GoogleSearches.monthsData.filter(e => {return dateRange.indexOf(e.period) !=-1; });
    const metaData = multiData.MetaPosts.monthsData.filter(e => {return dateRange.indexOf(e.period) !=-1; });
    const tiktokData = multiData.TiktokMentions.monthsData.filter(e => {return dateRange.indexOf(e.period) !=-1; });
    const options = {
      backgroundColor:'#ffffff',
      grid: {
        left: '80',
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(20,109,31,0.7)',
        borderColor: '#00A32E',
        textStyle: {
          color: '#FFFFFF',
        },
        formatter: function (params) {
          let tooltipContent = '<div>';
          params.forEach((item) => {
            tooltipContent += `
         <div style="display: flex; justify-content: space-between; align-items: center; width: 115px; margin: 4px 0;">
          <span style="display: inline-block; width: 12px; height: 12px; border-radius: 50%; background-color: ${item.color}; margin-right: 5px;"></span>
          <span style="flex: 1; text-align: left; font-size: 10px font-weight: 600">${item.seriesName}</span>
          <span>${item.value}%</span>
        </div>`;
          });
          tooltipContent += '</div>';
          return tooltipContent;
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: 'rgba(117, 123, 140, 1)',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          hideOverlap: true,
          margin: 10,
          padding: [0, 0, 0, 10],
        },
        name: 'Time',
        nameLocation: 'center',
        nameTextStyle: {
          color: 'rgba(117, 123, 140, 1)',
          fontSize: 12,
          padding: [20, 0, 0, 0],
        },
        data: data.map((item) => item.period),
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(140, 191, 113, 1)',
          },
        },
        axisLabel: {
          color: 'rgba(140, 191, 113, 1)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(20, 109, 31, 0.1)',
          },
        },
        name: 'Monthly Share',
        nameLocation: 'middle',
        nameTextStyle: {
          color: 'rgba(140, 191, 113, 1)',
          fontSize: 12,
          padding: [0, 0, 40, 0],
        },
      },
      
      series: [
        {
          name:'Meta',
          data: metaData.map((item) => item.value),
          type: 'line',
          itemStyle: {
            color: '#0066E5',
          },
          lineStyle: {
            color: '#0066E5',
            width: 3,
          },
        },
        {
          name:'Tiktok',
          data: tiktokData.map((item) => item.value),
          type: 'line',
          itemStyle: {
            color: '#00D6E5',
          },
          lineStyle: {
            color: '#00D6E5',
            width: 3,
          },
        },
        {
          name:'Google',
          data: googleData.map((item) => item.value),
          type: 'line',
          itemStyle: {
            color: '#F25353',
          },
          lineStyle: {
            color: '#F25353',
            width: 3,
          },
        },
      ],
    };
    setMonthLineOption(options);
    getMultiEchartsOption(options);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00'
      }}
      spin
    />
  );
  const splitColumnName = (columnName) => {
    return columnName.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  return (
    <div className='graph-component' id='test'>
      {loading ? (
        <Spin indicator={antIcon}>
          <div className='spin-icon'></div>
        </Spin>
      ) : noDataMessage ? (
        <div>
          <div><img src={noDataGraph} alt="No data available" /></div> 
          <div className='no-data-container'>{splitColumnName(graphId)} data is currently unavailable
          </div>
        </div>
      ) : (
        <LineCharts options={monthLineOption} />
      )}
    </div>
  );
};

export default GraphComponent;