import { Select } from 'antd';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getFilter } from '../../api';
import WeightJson from '../../assets/json/category-weight.json';
import Flag from '../../components/flag/index.jsx';
import HorizontalLine from './horizontal.jsx';
import {
  PackageAiItem as Item,
  PackageAiProduct as ProductItem,
} from './item.jsx';
import style from './tree.module.scss';

function useRowShowHook({ show, scrollToRow }) {
  const [display, setDisplay] = useState('none');
  const [opacity, setOpacity] = useState(0);
  const onShowChange = useCallback(
    (ss) => {
      if (ss) {
        setDisplay('flex');
        setTimeout(() => {
          scrollToRow && scrollToRow();
          setOpacity(1);
        }, 10);
      } else {
        setOpacity(0);
        setTimeout(() => {
          setDisplay('none');
        }, 300);
      }
    },
    [setDisplay, setOpacity, scrollToRow]
  );
  useEffect(() => {
    onShowChange(show);
  }, [show]);
  const styleMap = useMemo(() => {
    return {
      display,
      opacity,
    };
  }, [display, opacity]);

  return { styleMap };
}

function getIndexPosition(index, length) {
  if (!length) return 0;
  else if (length === 1) return 0;

  const half = (length - 1) / 2;

  if (index === 0) return -2;
  else if (index === length - 1) return 2;
  else if (index === half) return 0;
  else if (index < half) return -1;
  else return 1;
}

function getIndexPositionProduct(index) {
  return index === 0 ? 0 : 1;
}

/**
 * get score weight
 * @param {string} brand
 * @param {string} market
 * @param {string|undefined} category
 * @returns {number}
 */
function getScoreWeight(brand, market, category) {
  if (category) {
    return WeightJson[brand][market]?.[category] ?? 0.0;
  } else {
    // eslint-disable-next-line no-underscore-dangle
    return WeightJson[brand][market]?._value ?? 0.0;
  }
}

function Product({ className, data, indexPosition = 0 }) {
  return (
    <ProductItem
      className={className}
      title={data.title}
      image={data.cover}
      appeal={data.appeal}
      intuitiveAppeal={data.intuitive_appeal}
      indexPosition={indexPosition}
    />
  );
}

function ProductColumn({ className, show = false, items }) {
  const columnRef = useRef();
  const scrollToColumn = useCallback(() => {
    if (!columnRef.current) return;
    columnRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [columnRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow: scrollToColumn });

  return (
    <div
      ref={columnRef}
      className={classNames(style.productColumn, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Product
          key={item.id}
          data={item}
          indexPosition={getIndexPositionProduct(index)}
        />
      ))}
    </div>
  );
}

function Category({ className, data, indexPosition = 0, onlyOne }) {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <div className={style.categoryItemBox}>
      <HorizontalLine pos={indexPosition} showLeftChildren={showChildren} />
      <div className={classNames(style.categoryItem, className)}>
        <Item
          title={data.title}
          appeal={data.appeal}
          intuitiveAppeal={data.intuitive_appeal}
          hasParent={true}
          hasChildren={data.items.length > 0}
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          leftChildren={true}
          onlyOne={onlyOne}
        />
        <ProductColumn show={showChildren} items={data.items} />
      </div>
    </div>
  );
}

function CategoryRow({ className, show = false, brand, market, items }) {
  const rowRef = useRef();
  const scrollToRow = useCallback(() => {
    if (!rowRef.current) return;
    rowRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [rowRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow });

  const onlyOne = useMemo(() => items.length === 1, [items]);

  return (
    <div
      ref={rowRef}
      className={classNames(style.categoryRow, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Category
          key={item.id}
          brand={brand}
          market={market}
          data={item}
          indexPosition={getIndexPosition(index, items.length)}
          onlyOne={onlyOne}
        />
      ))}
    </div>
  );
}

function Market({ className, data, indexPosition = 0 }) {
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    setShowChildren(false);
  }, [data]);

  return (
    <div className={style.marketItemBox}>
      <HorizontalLine pos={indexPosition} />
      <div className={classNames(style.marketItem, className)}>
        <Item
          title={
            <div className={style.marketHeader}>
              <Flag className={style.marketFlag} country={data.title} />
              <div>{data.title}</div>
            </div>
          }
          appeal={data.appeal}
          intuitiveAppeal={data.intuitive_appeal}
          hasParent={true}
          hasChildren={data.items.length > 0}
          showChildren={showChildren}
          setShowChildren={setShowChildren}
        />
        <CategoryRow
          show={showChildren}
          market={data.title}
          items={data.items}
        />
      </div>
    </div>
  );
}

function MarketRow({ className, show = false, brand, items }) {
  const rowRef = useRef();
  const scrollToRow = useCallback(() => {
    if (!rowRef.current) return;
    rowRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [rowRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow });

  const onlyOne = useMemo(() => items.length === 1, [items]);

  return (
    <div
      ref={rowRef}
      className={classNames(style.marketRow, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Market
          key={item.id}
          brand={brand}
          data={item}
          indexPosition={getIndexPosition(index, items.length)}
          onlyOne={onlyOne}
        />
      ))}
    </div>
  );
}

function Root({ className, brand, setBrand, data }) {
  const [brandOptions, setBrandOptions] = useState([]);
  useEffect(() => {
    const f = async () => {
      const { data: vd } = await getFilter('vertical');
      const { data } = await getFilter('brand', {
        vertical: vd.filter(
          (e) => e !== 'Lifestyle Nutrition' && e !== 'Other Nutrition'
        ),
      });
      if (!data) return;
      const d2 = data.filter((e) => Boolean(WeightJson[e]));
      setBrandOptions(d2.map((d) => ({ label: d, value: d })));
    };
    f().catch((err) => {
      console.error('get brand options error', err);
    });
  }, [setBrandOptions]);

  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    setShowChildren(false);
  }, [brand, data]);

  return (
    <div className={style.rootItem}>
      <Item
        className={classNames(style.root, className)}
        title={
          <Select
            className={style.rootTitleSelect}
            options={brandOptions}
            value={brand}
            onChange={setBrand}
          />
        }
        appeal={0}
        intuitiveAppeal={0}
        hasChildren={data.items.length > 0}
        showChildren={showChildren}
        setShowChildren={setShowChildren}
        showScore={false}
      />
      <MarketRow show={showChildren} brand={brand} items={data.items} />
    </div>
  );
}

function PackageAiTree({ data, zoom = 1, brand = '', setBrand }) {
  const treeData = useMemo(() => {
    if (!brand) return null;
    
    const root = {
      id: 'root',
      level: 'brand',
      title: brand,
      items: new Map(),
    };

    if (!data) return root;
    const bw = WeightJson[brand];
    
    // build tree
    for (const d of data) {
      if (d.brand !== brand) continue;

      if (!bw[d.market]) continue;
      if (!bw[d.market][d.category]) continue;

      if (!root.items.has(d.market)) {
        root.items.set(d.market, {
          id: `market-${d.market}`,
          level: 'market',
          title: d.market,
          appeal: 0,
          intuitive_appeal: 0,
          items: new Map(),
        });
      }
      if (!root.items.get(d.market).items.has(d.category)) {
        root.items.get(d.market).items.set(d.category, {
          id: `category-${d.category}`,
          level: 'category',
          title: d.category,
          appeal: 0,
          intuitive_appeal: 0,
          items: [],
        });
      }

      root.items
        .get(d.market)
        .items.get(d.category)
        .items.push({
          id: `product-${d.sn}`,
          level: 'product',
          title: d.display_name,
          cover: d.cover,
          appeal: d.appeal,
          intuitive_appeal: d.intuitive_appeal,
        });
    }

    // change to array
    root.items = Array.from(root.items.values());
    root.items.forEach((m) => {
      m.items = Array.from(m.items.values());
    });

    // calcuate score
    for (const m of root.items) {
      m.appeal = 0;
      m.intuitive_appeal = 0;
      for (const c of m.items) {
        c.appeal =
          c.items.reduce((acc, cur) => acc + cur.appeal, 0) / c.items.length;
        c.intuitive_appeal =
          c.items.reduce((acc, cur) => acc + cur.intuitive_appeal, 0) /
          c.items.length;

        const cw = getScoreWeight(brand, m.title, c.title);
        m.appeal += c.appeal * cw;
        m.intuitive_appeal += c.intuitive_appeal * cw;
      }
    }

    return root;
  }, [brand, data]);

  if (!data) return null;
  return (
    <div
      className={style.treeContainer}
      style={{ transform: `scale(${zoom})` }}
    >
      <Root brand={brand} setBrand={setBrand} data={treeData} />
    </div>
  );
}

export default PackageAiTree;
