import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Box from '../box/index.jsx';
import ImageLazy from '../image-lazy/image-lazy.jsx';
import style from './table2.module.scss';

const GENERATION_OPTIONS = [
  { label: 'Gen X', value: 'Gen X' },
  { label: 'Millennials', value: 'Millennials' },
  { label: 'Gen Z', value: 'Gen Z' },
  { label: 'Baby Boomers', value: 'Baby Boomers' },
];
const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
const USER_VS_NONE_USER_OPTIONS = [
  { label: 'Users', value: 'Users' },
  { label: 'Non-Users', value: 'Non-Users' },
];
const SOCIAL_CLASS_OPTIONS = [
  { label: 'Low Income', value: 'Low Income' },
  { label: 'Middle Income', value: 'Middle Income' },
  { label: 'High Income', value: 'High Income' },
];
const SUB_FILTERS = {
  generation: GENERATION_OPTIONS,
  gender: GENDER_OPTIONS,
  user_vs_nouser: USER_VS_NONE_USER_OPTIONS,
  social_class: SOCIAL_CLASS_OPTIONS,
};

const PERCENT_KEYS = [
  'brand_logo_visibility',
  'variant_name_visibility',
  'appeal',
  'intuitive_appeal',
  'upi',
];

function formatValue(val = '-', key) {
  if (PERCENT_KEYS.includes(key)) {
    return `${val}%`;
  } else {
    return val;
  }
}

function Table({ className = '', data = [], keys = [], filter, subFilter }) {
  const productIds = useMemo(() => {
    return data.map((p) => p.sn);
  }, [data]);
  const options = useMemo(() => {
    if (!subFilter) return [];
    return SUB_FILTERS[subFilter] ?? [];
  }, [subFilter]);

  const [loading, setLoading] = useState(false);
  const [subData, setSubData] = useState(new Map());

  const getData = useCallback(async () => {
    if (!productIds.length) return;
    if (!options.length) return;
    setLoading(true);
    try {
      const l = new Map();
      for (const o of options) {
        const sp = {
          [subFilter]: [o.value],
        };
        const { data: pd } = await getProduct(
          filter,
          sp,
          undefined,
          undefined,
          undefined,
          productIds
        );
        const ppd = new Map();
        pd.forEach((p) => {
          ppd.set(p.sn, p);
        });
        l.set(o.value, ppd);
      }
      setSubData(l);
    } catch (err) {
      console.error('get table 2 data error', err);
    }
    setLoading(false);
  }, [productIds, options, setLoading]);

  useEffect(() => {
    void getData();
  }, [subFilter]);

  return (
    <div className={`${style.compareTable} ${className}`}>
      <div className={style.compareTableHeadRow}>
        <div></div>
        <div></div>
        {data.map((p) => {
          return (
            <div className={style.compareTableHeadCell} key={p.sn}>
              <ImageLazy src={p.cover} alt={p.display_name} />
              <div className={style.compareTableHeadCellText}>
                {p.display_name}
              </div>
            </div>
          );
        })}
      </div>
      <Box className={style.compareTableBody} loading={loading}>
        <div className={style.compareTableBodyScroll}>
          {keys.map((key) => {
            if (
              key.key !== 'brand_logo_visibility' &&
              key.key !== 'variant_name_visibility'
            ) {
              return (
                <div className={style.compareTableBodyRow} key={key.key}>
                  <div
                    className={`${style.compareTableBodyCell} ${style.compareTableBodyCellFirst}`}
                  >
                    {key.title}
                  </div>
                  <div>
                    {options.map((o) => {
                      return (
                        <div
                          key={`${key.key} ${o.value}`}
                          className={style.compareTableBodyRowSub}
                        >
                          <div className={style.compareTableBodyCell}>
                            {o.label}
                          </div>
                          {data.map((p) => {
                            return (
                              <div
                                className={style.compareTableBodyCell}
                                key={`${p.sn} ${o.value} ${key.key}`}
                              >
                                {formatValue(
                                  (
                                    subData.get(o.value)?.get(p.sn)?.[
                                      key.key
                                    ] ?? 0.0
                                  ).toFixed(1),
                                  key.key
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              return (
                <div className={style.compareTableBodyRow} key={key.key}>
                  <div
                    className={`${style.compareTableBodyCell} ${style.compareTableBodyCellFirst}`}
                  >
                    {key.title}
                  </div>
                  <div>
                    <div className={style.compareTableBodyRowSub}>
                      <div className={style.compareTableBodyCell} />
                      {data.map((p) => {
                        return (
                          <div
                            className={style.compareTableBodyCell}
                            key={`${p.sn} ${key.key}`}
                          >
                            {formatValue(
                              subData.get(options[0].value)?.get(p.sn)?.[
                                key.key
                              ] ?? 0.0,
                              key.key
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Box>
    </div>
  );
}

export default Table;
