import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import style from './header.module.scss';

function Header({ className, text, tip, sort, onSortClick }) {
  return (
    <div className={classNames(style.box1, className)}>
      <div className={style.box11}>
        <div className={style.text}>Index vs Category Average</div>
      </div>
      <div className={style.box12}>
        <div className={style.box121}>
          <div className={style.text}>{text}</div>
          <Tooltip placement="bottom" color="#015719" title={tip}>
            <QuestionCircleOutlined className={style.icon} />
          </Tooltip>
        </div>
        <div className={style.box122} onClick={onSortClick}>
          <div
            className={style.descIcon}
            style={{ display: sort === 'desc' ? 'none' : undefined }}
          />
          <div
            className={style.ascIcon}
            style={{ display: sort === 'asc' ? 'none' : undefined }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
