import { Button, Divider, Cascader } from 'antd';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import style from './index.module.scss';

export function PackageAiCascader({
  className,
  isMultiple = true,
  value,
  onChange,
  options,
  allowClear = false,
}) {
  const dropdownRender = useCallback(
    (menu) => {
      if (!allowClear) return menu;
      if (!options.length) return menu;

      return (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Button
            className={style.clearBtn}
            type="primary"
            size="small"
            onClick={() => {
              onChange([]);
            }}
          >
            Clear All
          </Button>
        </>
      );
    },
    [allowClear, options, onChange]
  );

  return (
    <Cascader
      className={classNames(style.select, className)}
      value={value}
      options={options}
      multiple={isMultiple}
      onChange={onChange}
      allowClear={allowClear}
      dropdownRender={dropdownRender}
    />
  );
}
