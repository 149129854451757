import React, { useEffect, useRef, useState } from 'react';
import './growth-trend.scss';
import DetailTitle from '../detail-title/detail-title.jsx';
import { fetchGrowth, fetchPost } from '../../api/request.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import GraphWithSelectors from '../tab/graphwithselectornew.jsx';
import {
  fullscreen,
  topRight,
  topLeft,
  bottomRight,
  bottomLeft,
  cancelGreenIcon,
  popuoClose,
} from '../../../../components/icons/icons.jsx';
import FullscreenLineCharts from '../fullscreen-line-charts/line-charts.jsx';

function GrowthTrend({
  trendingDishData = { trendingDishData },
  loading = { loading },
  err = { err },
}) {
  const [nowLoading, setNowLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [allTrendingDishData, setAllTrendingDishData] =
    useState(trendingDishData);
  const [echartsOption, setEchartsOption] = useState(null);
  // const [err, setErr] = useState(false);

  const ref = useRef(null);

  const data = {
    countries: trendingDishData.countries,
    brands: trendingDishData.brand,
    top_dishes: trendingDishData.topDish,
    top_dish_variant_id: trendingDishData.detail.top_dish_variant_id,
  };

  const [trendFullscreenOption, setTrendFullscreenOption] = useState(null);
  const [trendMultiFullscreenOption, setTrendMultiFullscreenOption] =
    useState(null);
  let trendFullscreenOptiontemp = {};
  let trendMultiFullscreenOptiontemp = {};

  const getEchartsOption = (optionData) => {
    // console.log('getEchartsOption',optionData)
    trendFullscreenOptiontemp = optionData;
  };
  const getMultiEchartsOption = (optionData) => {
    // console.log('getMultiEchartsOption',optionData)
    trendMultiFullscreenOptiontemp = optionData;
  };
  const [isFullscreen, setIsFullscreen] = useState(false);
  function clickThetrendFullscreen() {
    setIsFullscreen(true);
    setTrendFullscreenOption({
      ...trendFullscreenOptiontemp,
      type: 'thetrend',
    });
  }

  function clickMultiFullscreen() {
    setIsFullscreen(true);
    setTrendFullscreenOption({
      ...trendMultiFullscreenOptiontemp,
      type: 'channels',
    });
  }

  function exitFullscreen() {
    setIsFullscreen(false);
  }

  useEffect(() => {
    fetchGrowth(data)
      .then((res) => {
        setGraphData(res.data);
        setNowLoading(false);
        // console.log(res.data)
      })
      .catch((err) => {
        console.error('fetchPost error:', err);
        setErr(true);
      });
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  function GrowthBox({ type = { type }, growthData = { data } }) {
    // console.log(growthData)
    const tempMultiData = {};
    tempMultiData.GoogleSearches = growthData.GoogleSearches;
    tempMultiData.MetaPosts = growthData.MetaPosts;
    tempMultiData.TiktokMentions = growthData.TiktokMentions;
    const [multiData, setMultiData] = useState(tempMultiData);

    // console.log(multiData)
    return (
      <>
        {isFullscreen && (
          <div className="graphShowFullscreen">
            <div style={{ position: 'relative' }}>
              <img className="topright" src={topRight} />
              <img className="topleft" src={topLeft} />
              <img className="bottomright" src={bottomRight} />
              <img className="bottomleft" src={bottomLeft} />
              <div className="fullscreenTitle">
                {trendFullscreenOption?.type === 'thetrend'
                  ? 'How has the trend index evolved over time?'
                  : 'How is this trend performing across different channels?'}
                <img
                  onClick={exitFullscreen}
                  src={popuoClose}
                  className="close-icon"
                />
              </div>
              <FullscreenLineCharts options={trendFullscreenOption} />
            </div>
          </div>
        )}

        <div className="deepDiveGrowthBox">
          <div style={{ position: 'relative', width: '100%' }}>
            <img className="topright" src={topRight} />
            <img className="topleft" src={topLeft} />
            <img className="bottomright" src={bottomRight} />
            <img className="bottomleft" src={bottomLeft} />
            {type == 'thetrend' && (
              <div className="title">
                How has the trend index evolved over time?
                <img
                  onClick={clickThetrendFullscreen}
                  className="fullscreen"
                  src={fullscreen}
                />
              </div>
            )}
            {type == 'thetrend' &&
              (graphData.TrendIndexCurve ? (
                <GraphWithSelectors
                  countries={allTrendingDishData.countries}
                  brand={allTrendingDishData.brand}
                  topDish={allTrendingDishData.topDish}
                  trendingDishId={allTrendingDishData.trendingDishId}
                  graphData={graphData.TrendIndexCurve}
                  graphId="TrendIndexCurve"
                  getEchartsOption={getEchartsOption}
                />
              ) : (
                <div className="no-data-container">
                  <p>No data available for TrendIndexCurve</p>
                </div>
              ))}
            {type == 'channels' && (
              <div className="title" style={{ marginTop: '40px' }}>
                How is this trend performing across different channels?
                <img
                  onClick={clickMultiFullscreen}
                  className="fullscreen"
                  src={fullscreen}
                />
              </div>
            )}
            {type == 'channels' &&
              (graphData.TrendIndexCurve ? (
                <GraphWithSelectors
                  countries={allTrendingDishData.countries}
                  brand={allTrendingDishData.brand}
                  topDish={allTrendingDishData.topDish}
                  trendingDishId={allTrendingDishData.trendingDishId}
                  graphData={multiData}
                  graphId="channels"
                  getMultiEchartsOption={getMultiEchartsOption}
                  // getEchartsOption={getEchartsOption}
                />
              ) : (
                <div className="no-data-container">
                  <p>No data available for TrendIndexCurve</p>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <DetailTitle title={'Growth Trend'} />
      {nowLoading ? (
        antIcon
      ) : (
        <>
          <GrowthBox type={'thetrend'} growthData={graphData} />
          <GrowthBox type={'channels'} growthData={graphData} />
        </>
      )}
    </>
  );
}

export default GrowthTrend;
