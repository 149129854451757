/* eslint-disable indent */
import classNames from 'classnames';
import style from './tooltip.module.scss';

function buildTooltip(
  imgSrc,
  displayName,
  index1,
  value1,
  color1,
  index2,
  value2,
  color2
) {
  return `<div class="${style.tooltipContainer}">
      <div class="${classNames(
        style.corner,
        style.cornerTop,
        style.cornerLeft
      )}"></div>
      <div class="${classNames(
        style.corner,
        style.cornerBottom,
        style.cornerLeft
      )}"></div>
      <div class="${classNames(
        style.corner,
        style.cornerTop,
        style.cornerRight
      )}"></div>
      <div class="${classNames(
        style.corner,
        style.cornerBottom,
        style.cornerRight
      )}"></div>
      <div class="${style.tooltipBody}">
        <div class="${style.imgBox}">
          <img src="${imgSrc}" alt="${displayName}" />
        </div>
        <div class="${style.textBox}">
          <h3 class="${style.text1}">${displayName}</h3>
          <div class="${style.textBox2}">
            <div>
              <div class="${style.text2}">${index1}</div>
              <div class="${
                style.text3
              }" style="color: ${color1}">${value1}</div>
            </div>
            <div class="${style.textBox3}">
              <div class="${style.text2}">${index2}</div>
              <div class="${
                style.text3
              }" style="color: ${color2}">${value2}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="${style.bgAll}"></div>
    </div>`;
}

export default buildTooltip;
