import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import shareLogo from '../../../../assets/images/svg/share-topdish-icon.svg';

const SocialAnimatedWordCloud = ({ selectedWordCloud }) => {
  const [fontSizeRange, setFontSizeRange] = useState([12,48]); // Larger default font size range
  const [normalizedWords, setNormalizedWords] = useState([]);

  useEffect(() => {
    if (selectedWordCloud && selectedWordCloud.length > 0) {
      // Normalize word values to a range of 1 to 100
      const maxValue = Math.max(...selectedWordCloud.map((item) => item.value));
      const normalized = selectedWordCloud.map((item) => ({
        text: item.text,
        value: item.value
      }));
      setNormalizedWords(normalized);
    }
  }, [selectedWordCloud]);

  const options = {
    colors: ['#00A32E'],
    enableTooltip: true,
    deterministic: true,
    fontFamily: 'unilever-shilling-regular',
    fontSizes: fontSizeRange,
    height: '200',
    padding: 4,
    rotations: 0,
    rotationAngles: [0, 0],
    scale: 'sqrt',
    spiral: 'rectangular',
    transitionDuration: 1000,
    tooltipOptions:{
      allowHTML: true,
      theme: 'greentooltip',
    },
  };

  const getWordTooltip = (word) => {
    return `
      <div style="display: flex; align-items: center;">
        <span><img src=${shareLogo} alt="icon" style="margin-right: 10px;" /></span> 
        <span>${(word.value * 100).toFixed(2)}%</span>

      </div>
    `;
  };  

  const callbacks = {
    getWordTooltip,
  };

  return (
    <div className="top-dish-animated-word-cloud">
      <ReactWordcloud options={options} callbacks={callbacks} words={normalizedWords.slice(0, 29)} />
    </div>
  );
};

export default SocialAnimatedWordCloud;
