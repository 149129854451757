// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x5GgWPur7DuUglhvyPnH{display:inline-block;padding:8px 13px;cursor:pointer;background-repeat:no-repeat;background-size:100% 100%}.x5GgWPur7DuUglhvyPnH .ApKVKEcjOcQgG1HwpcxE{font-size:12px;font-weight:600;color:#fff}.x5GgWPur7DuUglhvyPnH.BeQnDlN_hvMFvjxAStDC{cursor:not-allowed;filter:grayscale(1)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/btn/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,gBAAA,CACA,cAAA,CACA,2BAAA,CACA,yBAAA,CAEA,4CACE,cAAA,CACA,eAAA,CACA,UAAA,CAGF,2CACE,kBAAA,CACA,mBAAA","sourcesContent":[".btn2 {\n  display: inline-block;\n  padding: 8px 13px;\n  cursor: pointer;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n\n  .text {\n    font-size: 12px;\n    font-weight: 600;\n    color: #fff;\n  }\n\n  &.disabled {\n    cursor: not-allowed;\n    filter: grayscale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn2": `x5GgWPur7DuUglhvyPnH`,
	"text": `ApKVKEcjOcQgG1HwpcxE`,
	"disabled": `BeQnDlN_hvMFvjxAStDC`
};
export default ___CSS_LOADER_EXPORT___;
