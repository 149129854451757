import { putFileKey } from '../../apis/file';
import { getPredictPackage, getPredictVariant } from '../../apis/serve';
import { uuid } from '../../utils/uuid';

function buildAois() {
  return [
    {
      name: 'Brand Logo',
      type: 'rect',
    },
    {
      name: 'Variant Name',
      type: 'rect',
    },
    {
      name: 'Secondary Element that helps with Brand Blocking (Optional)',
      type: 'rect',
    },
    {
      name: 'Secondary Element that helps with Variant Differentiation (Optional)',
      type: 'rect',
    },
  ];
}

function buildPoint(pp) {
  if (!pp?.length) return undefined;
  // eslint-disable-next-line no-unused-vars
  const [lt, rt, rb, lb] = pp;
  return [
    { x: lt.x, y: lt.y },
    { x: rb.x, y: rb.y },
  ];
}

function buildStandardFile(file) {
  return file.replace('rawimage', 'standardizer');
}

export async function buildContext(id) {
  const ctx = {
    basic: {
      numberOfOwnNewPackDesigns: 0,
      numberOfVariantsPerDesign: 0,
    },
    asset: {
      unilever: [],
      competitor1: {
        id: uuid(),
        name: 'Current Design',
        variants: [],
      },
      competitor2: {
        id: uuid(),
        name: 'Current Design',
        variants: [],
      },
    },
    aoi: {
      unilever: [],
      competitor1: {
        id: uuid(),
        variants: [],
      },
      competitor2: {
        id: uuid(),
        variants: [],
      },
    },
  };

  const { data: pp } = await getPredictPackage(id);
  if (!pp) return;

  // set basic
  ctx.basic.projectName = pp.project_name;
  ctx.basic.businessObjective = pp.business_obj;
  ctx.basic.category = pp.category;
  ctx.basic.unileverBrandName = pp.brand_ul;
  ctx.basic.competitor1BrandName = pp.brand_c1;
  ctx.basic.competitor2BrandName = pp.brand_c2;
  for (let i = 1; i <= 3; i += 1) {
    const ppkey = `ul_design${i}_variant0`;
    const file = pp[ppkey];
    if (!file) continue;
    ctx.basic.numberOfOwnNewPackDesigns += 1;
  }
  if (pp.ul_design0_variant0) {
    for (let j = 0; j <= 3; j += 1) {
      const ppkey = `ul_design0_variant${j}`;
      const file = pp[ppkey];
      if (!file) continue;
      ctx.basic.numberOfVariantsPerDesign += 1;
    }
  } else {
    for (let j = 0; j <= 3; j += 1) {
      const ppkey = `ul_design1_variant${j}`;
      const file = pp[ppkey];
      if (!file) continue;
      ctx.basic.numberOfVariantsPerDesign = j + 1;
    }
  }

  // set asset
  ctx.asset.allowPublic = pp.is_share;
  ctx.asset.unilever.push({
    id: uuid(),
    name: 'Current Design',
    variants: [],
  });
  for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j += 1) {
    const vv = `Variant ${j + 1}`;
    const ppkey = `ul_design0_variant${j}`;
    const file = pp[ppkey];
    if (file) {
      ctx.asset.unilever[0].variants.push({
        id: uuid(),
        name: vv,
        file: file,
        standardFile: buildStandardFile(file),
      });
    }
  }
  for (let i = 1; i <= ctx.basic.numberOfOwnNewPackDesigns; i += 1) {
    ctx.asset.unilever.push({
      id: uuid(),
      name:
        ctx.basic.numberOfOwnNewPackDesigns > 1
          ? `New Design ${i}`
          : 'New Design',
      variants: [],
    });
    for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j += 1) {
      const vv = `Variant ${j + 1}`;
      const ppkey = `ul_design${i}_variant${j}`;
      const file = pp[ppkey];
      if (file) {
        ctx.asset.unilever[i].variants.push({
          id: uuid(),
          name: vv,
          file: file,
          standardFile: buildStandardFile(file),
        });
      }
    }
  }
  for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j += 1) {
    const vv = `Variant ${j + 1}`;
    const c1key = `c1_design1_variant${j}`;
    const c1file = pp[c1key];
    if (c1file) {
      ctx.asset.competitor1.variants.push({
        id: uuid(),
        name: vv,
        file: c1file,
        standardFile: buildStandardFile(c1file),
      });
    }
    const c2key = `c2_design1_variant${j}`;
    const c2file = pp[c2key];
    if (c2file) {
      ctx.asset.competitor2.variants.push({
        id: uuid(),
        name: vv,
        file: c2file,
        standardFile: buildStandardFile(c2file),
      });
    }
  }

  const processVariant = async (v) => {
    if (v.file) {
      const { data: vd } = await putFileKey(v.file);
      v.url = vd.url;
    }
    if (v.standardFile) {
      const { data: vd } = await putFileKey(v.standardFile);
      v.standardUrl = vd.url;
    }
    if (v.editedFile) {
      const { data: vd } = await putFileKey(v.editedFile);
      v.editedUrl = vd.url;
    }
  };
  for (const d of ctx.asset.unilever) {
    for (const v of d.variants) {
      await processVariant(v);
    }
  }
  for (const v of ctx.asset.competitor1.variants) {
    await processVariant(v);
  }
  for (const v of ctx.asset.competitor2.variants) {
    await processVariant(v);
  }

  // set aoi
  for (let i = 0; i <= ctx.basic.numberOfOwnNewPackDesigns; i++) {
    ctx.aoi.unilever.push({
      id: uuid(),
      variants: [],
    });
    for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j++) {
      const ppkey = `ul_design${i}_variant${j}`;
      const file = pp[ppkey];
      if (!file) continue;
      const vid = pp[`${ppkey}_id`];
      const { data } = await getPredictVariant(vid);
      if (!data) {
        console.warn('null predict variant', vid);
        continue;
      }
      const aois = buildAois();
      aois[0].points = buildPoint(data.points?.logo);
      aois[1].points = buildPoint(data.points?.variant);
      aois[2].points = buildPoint(data.points?.brand);
      aois[3].points = buildPoint(data.points?.aoi);
      ctx.aoi.unilever[i].variants.push({
        id: uuid(),
        aois: aois,
      });
    }
  }
  for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j += 1) {
    const ppkey = `c1_design1_variant${j}`;
    const file = pp[ppkey];
    if (!file) continue;
    const vid = pp[`${ppkey}_id`];
    const { data } = await getPredictVariant(vid);
    if (!data) {
      console.warn('null predict variant', vid);
      continue;
    }
    const aois = buildAois();
    aois[0].points = buildPoint(data.points?.logo);
    aois[1].points = buildPoint(data.points?.variant);
    aois[2].points = buildPoint(data.points?.brand);
    aois[3].points = buildPoint(data.points?.aoi);
    ctx.aoi.competitor1.variants.push({
      id: uuid(),
      aois: aois,
    });
  }
  for (let j = 0; j < ctx.basic.numberOfVariantsPerDesign; j += 1) {
    const ppkey = `c2_design1_variant${j}`;
    const file = pp[ppkey];
    if (!file) continue;
    const vid = pp[`${ppkey}_id`];
    const { data } = await getPredictVariant(vid);
    if (!data) {
      console.warn('null predict variant', vid);
      continue;
    }
    const aois = buildAois();
    aois[0].points = buildPoint(data.points?.logo);
    aois[1].points = buildPoint(data.points?.variant);
    aois[2].points = buildPoint(data.points?.brand);
    aois[3].points = buildPoint(data.points?.aoi);
    ctx.aoi.competitor2.variants.push({
      id: uuid(),
      aois: aois,
    });
  }

  return ctx;
}

function processVariant(vs) {
  for (const v of vs) {
    delete v.url;
    delete v.standardUrl;
    delete v.editedUrl;
  }
}

export function buildTemplateContext(
  ctx,
  deleteNewDesign = false,
  useTemplate
) {
  delete ctx.res;

  for (const d of ctx.asset.unilever) {
    processVariant(d.variants);
  }
  processVariant(ctx.asset.competitor1.variants);
  processVariant(ctx.asset.competitor2.variants);

  if (ctx.asset.unilever?.[1]?.variants?.length) {
    if (deleteNewDesign) {
      delete ctx.asset.unilever[1].variants[0].file;
      delete ctx.asset.unilever[1].variants[0].standardFile;
      delete ctx.asset.unilever[1].variants[0].complete;
      delete ctx.asset.unilever[1].variants[0].editedFile;
      delete ctx.asset.unilever[1].variants[0].x;
      delete ctx.asset.unilever[1].variants[0].y;
    }
  }

  ctx.useTemplate = Boolean(useTemplate);

  return ctx;
}
