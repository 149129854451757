import React, { useState, useEffect, useRef } from 'react';
import MarketSelect from '../top-dish-market/top-dish-market.jsx';
import TopDishSelect from '../top-dish-filtern/top-dish-filter.jsx';
import BrandSelect from '../brand-filter/brand-filter.jsx';
import TopDishesTable from '../top-dish-table/top-dish-table.jsx';
import { fetchLookup, fetchWordCloud } from '../../api/request.js';
import { aiTopDishInfoWhite } from '../../../../components/icons/icons.jsx';
import GuidePopup from '../guide-pop-up/guide-pop-up.jsx';

const MarketBrandDishFilter = () => {
  const [lookupData, setLookupData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState(['US']);
  const [selectedBrands, setSelectedBrands] = useState(['Hellmann\'s']);
  const [selectedDishes, setSelectedDishes] = useState(['Burgers']);
  const [selectedWordCloud, setSelectedWordCloud] = useState([]);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const topDishesTableRef = useRef(null);
  const [newMarketData, setNewMarketData] = useState([]);
  const [wordCSelectedData, setWordCSelectedData] = useState([]);

  useEffect(() => {
    fetchLookup()
      .then(res => {
        const data = res.data || {};
        const marketData = data.market || [];     
        setLookupData(marketData);
        setMarketData(marketData);     
      })
      .catch(err => {
        console.error('fetchLookup error:', err);
        setErr(true);
      });
  }, []);
  useEffect(() => {
    if (selectedMarkets.length > 0 && selectedBrands.length > 0) {
      handleMarketChange(selectedMarkets);
    }
  }, []);
  
  const handleMarketChange = async (values) => {
    const selectedMarkets = Array.isArray(values) ? values : [values];
    setSelectedMarkets(selectedMarkets);
  
    try {
      const res = await fetchLookup();
      const data = res.data || {};
      const fetchedMarketData = data.market || [];
  
      setLookupData(fetchedMarketData);
      setNewMarketData(fetchedMarketData);
  
      const brandsForSelectedMarkets = selectedMarkets.flatMap(market =>
        (fetchedMarketData.find(data => data.name === market)?.brands || [])
      );
  
      const uniqueBrands = removeDuplicatesAndMerge(brandsForSelectedMarkets, 'name');
  
      if (uniqueBrands.length > 0) {
        setSelectedBrands([uniqueBrands[0].name]);
  
        const topDishes = uniqueBrands.flatMap(brand => brand.topDishes);
  
        if (selectedMarkets.includes('US') && uniqueBrands.some(brand => brand.name === "Hellmann's")) {
          setSelectedDishes(["Burgers"]);
        } else {
          setSelectedDishes(topDishes.length > 0 ? [topDishes[0]] : ["Burgers"]);
        }
      } else {
        setSelectedBrands([]);
        setSelectedDishes([]);
      }
    } catch (error) {
      console.error('Error handling market change:', error);
    }
  };
  
  const handleBrandChange = (values) => {
    setSelectedBrands(values);
    const marketsToProcess = Array.isArray(selectedMarkets) ? selectedMarkets : [selectedMarkets];
    
    const topDishes = marketsToProcess.flatMap(marketName => {
      const market = lookupData.find(data => data.name === marketName);
      if (market) {
        return market.brands
          .filter(brand => values.includes(brand.name))  
          .flatMap(brand => brand.topDishes);  
      }
      return [];
    });
  
    const uniqueTopDishes = [...new Set(topDishes.map(dish => JSON.stringify(dish)))] 
      .map(dish => JSON.parse(dish)); 
  
    const firstTopDish = uniqueTopDishes.length > 0 ? [uniqueTopDishes[0]] : [];
  
    setSelectedDishes(firstTopDish);
  };  
  
  const handleDishChange = (value) => {
    setSelectedDishes(value);
  };

  function removeDuplicatesAndMerge(arr, prop) {
    const uniqueMap = new Map();

    arr.forEach(obj => {
      const key = obj[prop];
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
      } else {
        const existingObj = uniqueMap.get(key);
        existingObj.topDishes = Array.from(new Set([...existingObj.topDishes, ...obj.topDishes]));
      }
    });

    return Array.from(uniqueMap.values());
  }

  const uniqueBrandsForDropdown = removeDuplicatesAndMerge(
    selectedMarkets.flatMap(market =>
      (lookupData.find(data => data.name === market)?.brands || [])
    ), 'name'
  );

  const uniqueTopDishes = selectedBrands.flatMap(brandName =>
    lookupData.flatMap(market => {
      const isSelectedMarket = selectedMarkets.length === 1
        ? selectedMarkets[0] === market.name
        : selectedMarkets.includes(market.name);

      if (isSelectedMarket) {
        const brand = market.brands.find(b => b.name === brandName);
        return brand ? brand.topDishes : [];
      }
      return [];
    })
  );

  const uniqueDishesSet = [...new Set(uniqueTopDishes)];

  const dropdownListStyle = {
    background: '#007118',
    border: '1px solid #19FF40',
    borderRadius:'0px',
    fontFamily: 'unilever-shilling-regular',
    padding:'0px',
    margin:'0px'
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleButtonClick = () => {
    if (selectedDishes && selectedDishes.length === 1) {
      const data = {
        countries: selectedMarkets,
        brands: selectedBrands,
        top_dish: selectedDishes.toString()
      };
      setWordCSelectedData(selectedDishes);
    }

    topDishesTableRef.current.handleApplyClick();
  };

  const isApplyButtonDisabled = !(selectedBrands.length > 0 && selectedDishes.length > 0);

  return (
    <>
      <GuidePopup isVisible={isPopupVisible} onClose={togglePopup} />
      <div className='ai-topdish-filter-container'>
        <div className="inputBorder">
          <MarketSelect
            dropdownStyle={dropdownListStyle}
            markets={newMarketData.map(market => market)}
            selectedMarket={selectedMarkets}
            onChange={handleMarketChange}
          />
        </div>
        <div className="inputBorder">
          <BrandSelect
            mode="multiple"
            dropdownStyle={dropdownListStyle}
            brands={uniqueBrandsForDropdown.map(brand => brand)}
            selectedBrands={selectedBrands}
            onChange={handleBrandChange}
          />
        </div>
        <div className="inputBorder">
          <TopDishSelect
            dropdownStyle={dropdownListStyle}
            topDishes={uniqueDishesSet}
            selectedDishes={selectedDishes}
            onChange={handleDishChange}
          />
        </div>
        <div className={`applyBorder ${isApplyButtonDisabled ? 'disabled' : ''}`}>
          <button 
            type="button" 
            className="applyButton" 
            onClick={handleButtonClick}
            disabled={isApplyButtonDisabled}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="guide-content guide-icon"><span onClick={togglePopup}><img src={aiTopDishInfoWhite} width='26' height='26' alt='info' /></span></div>
      <div className='ml-10'>
        <TopDishesTable 
          ref={topDishesTableRef}
          brand={selectedBrands} 
          value={selectedDishes} 
          country={selectedMarkets} 
          marketData={marketData}
          loading={loading}
          err={err}
        />
      </div>
    </>
  );
};

export default MarketBrandDishFilter;
