import React, { useRef, useState, useCallback, useEffect } from 'react';
import './deep-dive-body.scss';
import jsPDF from 'jspdf';
import { Spin } from 'antd';
import TrendSummary from '../trend-summary/trend-summary.jsx';
import GrowthTrend from '../growth-trend/growth-trend.jsx';
import SocialBuzz from '../social-buzz/social-buzz.jsx';
import KeyIngredients from '../key-ingredients/key-ingredients.jsx';
import {
  arrowRightGreen,
  exportTopRight,
  exportTopLeft,
  exportBottomRight,
  exportBottomLeft,
  exportLogo,
  popuoClose,
  ki,
} from '../../../../components/icons/icons.jsx';
import html2canvas from 'html2canvas';

function DeepDiveBody({
  changeIsDeepDiveVisible = changeIsDeepDiveVisible,
  loading = { loading },
  err = { err },
  trendingDishData = { trendingDishData },
}) {
  const sideMenuItems = [
    { title: 'Trend Summary' },
    { title: 'Growth Trend' },
    { title: 'Social Buzz' },
    { title: 'Key Ingredients' },
  ];
  const [selectedTitle, setSelectedTitle] = useState('Trend Summary');
  const [exportLoading, setExportLoading] = useState(false);
  const [isManualScroll, setIsManualScroll] = useState(false);

  const convertVideosToCanvas = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.className = 'video-snapshot';
      video.style.display = 'none';
      video.parentNode.insertBefore(canvas, video);
    });
  };

  const restoreVideos = () => {
    const canvases = document.querySelectorAll('.video-snapshot');
    canvases.forEach((canvas) => {
      const video = canvas.nextElementSibling;
      video.style.display = '';
      canvas.remove();
    });
  };

  const exportTrendingDish =
    trendingDishData?.trendingDish?.[0]?.replace(/\s+/g, '') ||
    'dish-variant-deep-dive';

  const getFormattedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const exportPDF = async () => {
    console.log('click');
    setExportLoading(true);

    const sections = document.querySelectorAll('.section');

    if (!sections || sections.length === 0) {
      console.error('Export failed: No sections found.');
      setExportLoading(false);
      return;
    }

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const pageHeight = 297;
    const generateCanvas = async (section, index) => {
      return new Promise((resolve) => {
        requestAnimationFrame(async () => {
          try {
            const canvas = await html2canvas(section, {
              scale: 1.8,
              useCORS: true,
              backgroundColor: '#000000',
            });

            const imgData = canvas.toDataURL('image/jpeg', 0.6);
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const imgHeight = Math.ceil(
              (canvasHeight * imgWidth) / canvasWidth
            );

            let remainingHeight = imgHeight;
            let yOffset = 0;

            while (remainingHeight > 0) {
              const pageContentHeight = Math.min(pageHeight, remainingHeight);
              pdf.setFillColor(0, 0, 0);
              pdf.rect(0, 0, imgWidth, pageHeight, 'F');

              pdf.addImage(
                imgData,
                'JPEG',
                0,
                yOffset,
                imgWidth,
                pageContentHeight
              );

              remainingHeight -= pageHeight;
              yOffset -= pageHeight;

              if (remainingHeight > 0) pdf.addPage();
            }

            resolve();
          } catch (error) {
            console.error(`Error exporting section ${index + 1}:`, error);
            resolve();
          }
        });
      });
    };

    const exportSections = async () => {
      const visibleSections = Array.from(sections).filter(
        (section) => section.offsetHeight > 0
      );

      for (let i = 0; i < visibleSections.length; i++) {
        await generateCanvas(visibleSections[i], i);
        if (i !== visibleSections.length - 1) {
          pdf.addPage();
          pdf.setFillColor(0, 0, 0);
          pdf.rect(0, 0, imgWidth, pageHeight, 'F');
        }
      }

      const formattedTimestamp = getFormattedTimestamp();
      const fileName = `TopDishVariant_${exportTrendingDish}_${formattedTimestamp}.pdf`;

      pdf.save(fileName);
    };

    await exportSections();

    setExportLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!isManualScroll) {
          for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            if (entry.isIntersecting) {
              setSelectedTitle(entry.target.id);
              break;
            }
          }
        }
      },
      { threshold: 0.25, rootMargin: '0px 0px 0px 0px' }
    );

    observer.observe(TrendSummaryRef.current);
    observer.observe(GrowthTrendRef.current);
    observer.observe(SocialBuzzRef.current);
    observer.observe(KeyIngredientsRef.current);

    return () => observer.disconnect();
  }, [isManualScroll]);

  const SideMenuItem = ({ item, index }) => {
    if (selectedTitle == item.title) {
      return (
        <div
          className="topDishSideMenuItem"
          onClick={(e) => handleSideMenuClick(item, index)}
        >
          <div className="sideMenuText" style={{ color: '#26F028' }}>
            {item.title}
          </div>
          <img className="selectedArrow" src={arrowRightGreen} />
        </div>
      );
    } else {
      return (
        <div
          className="topDishSideMenuItem"
          onClick={(e) => handleSideMenuClick(item, index)}
        >
          <div className="sideMenuText">{item.title}</div>
        </div>
      );
    }
  };

  const TrendSummaryRef = useRef(null);
  const GrowthTrendRef = useRef(null);
  const SocialBuzzRef = useRef(null);
  const KeyIngredientsRef = useRef(null);
  const tsRef = useRef(null);
  const gtRef = useRef(null);
  const sbRef = useRef(null);
  const kiRef = useRef(null);

  const handleSideMenuClick = (e, index) => {
    setIsManualScroll(true);
    if (e.title == 'Trend Summary') {
      TrendSummaryRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (e.title == 'Growth Trend') {
      GrowthTrendRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (e.title == 'Social Buzz') {
      SocialBuzzRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (e.title == 'Key Ingredients') {
      KeyIngredientsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => setIsManualScroll(false), 600);
  };

  const handleClose = () => {
    changeIsDeepDiveVisible(false);
  };

  return (
    <div className="container-top-dish">
      <div className="sideMenu">
        {sideMenuItems.map((item, index) => (
          <SideMenuItem item={item} key={index} />
        ))}
      </div>
      <div className="deepDiveborderLine"></div>

      <div
        className="exportBtn"
        style={{
          pointerEvents: exportLoading ? 'none' : 'auto',
          cursor: exportLoading ? 'not-allowed' : 'pointer',
        }}
        onClick={exportPDF}
      >
        <img
          className={`exportLogo ${exportLoading ? 'loading' : ''}`}
          src={exportLogo}
        />
        <img className="exportTR" src={exportTopRight} />
        <img className="exportTL" src={exportTopLeft} />
        <img className="exportBR" src={exportBottomRight} />
        <img className="exportBL" src={exportBottomLeft} />
        <div className={`exportText ${exportLoading ? 'loading' : ''}`}>
          Export
        </div>
        {exportLoading && <Spin />}
      </div>
      <div className="popupclose" onClick={handleClose}>
        <img src={popuoClose} />
      </div>
      <div className="deepDiveDetail" id="divToExport">
        <div ref={TrendSummaryRef} id="Trend Summary" className="section">
          <TrendSummary trendingDishData={trendingDishData} ref={tsRef} />
        </div>
        <div ref={GrowthTrendRef} id="Growth Trend" className="section">
          <GrowthTrend
            loading={loading}
            err={err}
            trendingDishData={trendingDishData}
            ref={gtRef}
          />
        </div>
        <div ref={SocialBuzzRef} id="Social Buzz" className="section">
          <SocialBuzz
            loading={loading}
            err={err}
            trendingDishData={trendingDishData}
            ref={sbRef}
          />
        </div>
        <div ref={KeyIngredientsRef} id="Key Ingredients" className="section">
          <KeyIngredients ref={kiRef} />
        </div>
      </div>
    </div>
  );
}

export default DeepDiveBody;
