import React, { useEffect, useState, forwardRef } from 'react';
import './social-buzz.scss';
import { tiktokLogoWhite, metaLogoWhite } from '../../../../components/icons/icons.jsx';
import DetailTitle from '../detail-title/detail-title.jsx';
import SocialWordCloud from '../social-word-cloud/social-word-cloud.jsx';
import { fetchPost, fetchWordCloud } from '../../api/request.js';
import VideoComponent from '../tiktok-modal-tab/tiktok-modal.jsx';
import MetaPostComponent from '../meta-post/meta-post-modal.jsx';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import RelatedTheme from '../related-theme/related-theme.jsx';
import { Empty } from 'antd';

function LogoLabel(e) {
  return (
    <div className='label'>
      <img className='labelArrow' src={e.logo} />
      <div className='labelText'>{e.name}</div>
    </div>
  );
}

const SocialBuzz = forwardRef(({ loading, err, trendingDishData }, ref) => {
  const [metaPosts, setMetaPosts] = useState([]);
  const [tiktokMentions, setTiktokMentions] = useState([]);
  const [nowLoading, setNowLoading] = useState(true);
  const [themeData, setThemeData] = useState([]);
  const [selectedWordCloud, setSelectedWordCloud] = useState([]);
  const [errorPost, setErrorPost] = useState(false);
  const [errorWC, setErrorWC] = useState(false);

  const data = {
    countries: trendingDishData.countries,
    brands: trendingDishData.brand,
    top_dishes: trendingDishData.topDish,
    top_dish_variant_id: trendingDishData.detail.top_dish_variant_id,
  };
  const WordCloudData = {
    countries: trendingDishData.countries,
    brands: trendingDishData.brand,
    top_dish: trendingDishData.topDish[0],
    top_dish_variant_id: trendingDishData.detail.top_dish_variant_id,
  };

  useEffect(() => {
    fetchPost(data)
      .then(res => {
        setMetaPosts(res.data.meta_posts || []);
        setTiktokMentions(res.data.tiktok_mentions || []);
        setThemeData(res.data.relevant_themes || []);
        setNowLoading(false);
      })
      .catch(err1 => {
        console.error('fetchPost error:', err1);
        setErrorPost(true);
      });

    fetchWordCloud(WordCloudData)
      .then(res1 => {
        setSelectedWordCloud(res1.data);
      })
      .catch(err2 => {
        console.error('fetchWordCloud error:', err2);
        setErrorWC(true);
      });
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  const isMetaPostsEmpty = !metaPosts.length;
  const isTiktokMentionsEmpty = !tiktokMentions.length;
  const isSelectedWordCloudEmpty = !selectedWordCloud.length;
  return (
    <>
      <div ref={ref} id='Social Buzz'>
        <DetailTitle title={'Social Buzz'} />
      </div>

      {nowLoading ? (
        antIcon
      ) : (
        <>
          {isSelectedWordCloudEmpty ? (
            <Empty className="emptyTable" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data available" />
          ) : (
            <SocialWordCloud selectedWordCloud={selectedWordCloud} />
          )}
          <LogoLabel name={'Tiktok'} logo={tiktokLogoWhite} />
          <RelatedTheme themeData={themeData} />
          {isTiktokMentionsEmpty ? (
            <Empty className="emptyTable" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data available" />
          ) : (
            <>
              <VideoComponent videoData={tiktokMentions} trendingDish={themeData.trendingDish} />
            </>
          )}

          <LogoLabel name={'Meta'} logo={metaLogoWhite} />
          {isMetaPostsEmpty ? (
            <Empty className="emptyTable" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data available" />
          ) : (
            <>
              <div className='mt-20'>
                <MetaPostComponent videoData={metaPosts} trendingDish={themeData.trendingDish} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
});
SocialBuzz.displayName = 'SocialBuzz';
export default SocialBuzz;