import React, { useRef, useState } from 'react';
import './detail-title.scss';
import {arrowRightGreen2} from '../../../../components/icons/icons.jsx';

function DetailTitle(e) {
  return(
    <div className='deepDiveTitleBox'>
      <img className='deepDiveTitleArrow' src={arrowRightGreen2}/>
      <div className='deepDiveTitleText'>{e.title}</div>
    </div>
  );
}

export default DetailTitle;