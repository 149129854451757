import React, { createContext, useCallback, useMemo, useState } from 'react';

function buildId({ market, sn }) {
  return `${market}-${sn}`;
}

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [avgs, setAvgs] = useState([]);
  const [originData, setOriginData] = useState([]);

  const categoryRankData = useMemo(() => {
    const categoryRankMap = new Map();

    // loop all product
    for (const d of originData) {
      if (categoryRankMap.has(d.category)) {
        categoryRankMap.get(d.category).push(d);
      } else {
        categoryRankMap.set(d.category, [d]);
      }
    }

    const newRank = () => ({
      brand_logo_visibility: [],
      variant_name_visibility: [],
      intuitive_appeal: [],
      appeal: [],
      upi: [],

      taste: [],
      premium: [],
      natural: [],
      value: [],
      sustainable: [],
      modernity: [],
      excitement: [],
      desirability: [],
    });

    const sortRank = (list) => {
      const sl = list.sort((a, b) => b.value - a.value);
      const ll = list.length;

      const map = new Map();
      for (let i = 0; i < ll; i++) {
        map.set(buildId(sl[i]), i / ll);
      }

      return map;
    };

    for (const [key, value] of categoryRankMap) {
      const rank = newRank();
      for (const v of value) {
        const vv = { market: v.market, sn: v.sn };

        rank.brand_logo_visibility.push({
          ...vv,
          value: v.brand_logo_visibility,
        });
        rank.variant_name_visibility.push({
          ...vv,
          value: v.variant_name_visibility,
        });
        rank.intuitive_appeal.push({ ...vv, value: v.intuitive_appeal });
        rank.appeal.push({ ...vv, value: v.appeal });
        rank.upi.push({ ...vv, value: v.upi });

        rank.taste.push({ ...vv, value: v.taste });
        rank.premium.push({ ...vv, value: v.premium });
        rank.natural.push({ ...vv, value: v.natural });
        rank.value.push({ ...vv, value: v.value });
        rank.sustainable.push({ ...vv, value: v.sustainable });
        rank.modernity.push({ ...vv, value: v.modernity });
        rank.excitement.push({ ...vv, value: v.excitement });
        rank.desirability.push({ ...vv, value: v.desirability });
      }

      rank.brand_logo_visibility = sortRank(rank.brand_logo_visibility);
      rank.variant_name_visibility = sortRank(rank.variant_name_visibility);
      rank.intuitive_appeal = sortRank(rank.intuitive_appeal);
      rank.appeal = sortRank(rank.appeal);
      rank.upi = sortRank(rank.upi);

      rank.taste = sortRank(rank.taste);
      rank.premium = sortRank(rank.premium);
      rank.natural = sortRank(rank.natural);
      rank.value = sortRank(rank.value);
      rank.sustainable = sortRank(rank.sustainable);
      rank.modernity = sortRank(rank.modernity);
      rank.excitement = sortRank(rank.excitement);
      rank.desirability = sortRank(rank.desirability);

      categoryRankMap.set(key, rank);
    }

    return categoryRankMap;
  }, [originData]);

  const getCategoryRank = useCallback(
    (category, key, market, sn) => {
      if (!key) return 1;
      // console.log('category', dv);
      const vv = categoryRankData.get(category);
      // console.log('vrd', vv);
      if (!vv) return 1;
      const vvv = vv[key];
      // console.log('vvv', vvv);
      if (!vvv) return 1;
      const vvvv = vvv.get(buildId({ market, sn }));
      // console.log('vvvv', vvvv);
      return vvvv ?? 1;
    },
    [categoryRankData]
  );

  const showModal = (obj, avgs, originData) => {
    setIsOpen(true);
    setModalData(obj);
    setAvgs(avgs);
    setOriginData(originData);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalData({});
    setAvgs([]);
    setOriginData([]);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalData,
        showModal,
        closeModal,
        avgs,
        getCategoryRank,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
