// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gZpL2V_A5PU_afxSkirc{width:100%}.qtrVXB_7uoCOO4n9wk_L{margin:0px 10px 5px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-performance/components/cascader/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,mBAAA","sourcesContent":[".select {\n  width: 100%;\n}\n\n.clearBtn {\n  margin: 0px 10px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `gZpL2V_A5PU_afxSkirc`,
	"clearBtn": `qtrVXB_7uoCOO4n9wk_L`
};
export default ___CSS_LOADER_EXPORT___;
