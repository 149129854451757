import React,{useEffect,forwardRef} from 'react';
import './deep-dive-pop-up.scss';
import BorderContainer from '../border-container/border-container-small.jsx';
import DeepDiveBody from '../deep-dive-body/deep-dive-body.jsx';

const DeepDivePopup = forwardRef(({ isDeepDiveVisible,changeIsDeepDiveVisible,loading,err,trendingDishData}, ref) => {
  const dishData = trendingDishData;
  const title = dishData.trendingDish;

  useEffect(() => {
    if (isDeepDiveVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isDeepDiveVisible]);

  return (
    <div className="deepDivee-pop-up-top-dish" ref={ref}>
      <div className={`popup ${isDeepDiveVisible ? 'show' : ''}`}>
        <div className="popup-content">
          <BorderContainer hideLeftCorner headerTitle={`Deep Dive - ${title}`} headerTitleSize='20px'>
            <DeepDiveBody changeIsDeepDiveVisible={changeIsDeepDiveVisible} loading={loading} err={err} trendingDishData={trendingDishData} />
          </BorderContainer>
        </div>
      </div>
    </div>
  );
});

DeepDivePopup.displayName = 'DeepDivePopup';

export default DeepDivePopup;