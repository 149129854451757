import React, { forwardRef, useEffect, useState } from 'react';
import './trend-summary.scss';
import DetailTitle from '../detail-title/detail-title.jsx';
import NoImage from '../../../../assets/images/ai-top-dish/no-preview-available.png';
import { pythonApiURL } from '../../../../env-url.js';
import { getToken } from '../../../../util/api.jsx';


const TrendSummary = forwardRef(({ trendingDishData }, ref) => {
  const dishData = trendingDishData;
  const [newToken, setToken] = useState('');
  const fileprefix = `${pythonApiURL}/v1/tdti/filestream?fileinfo=`;

  const getTokenFromInstance = async () => {
    let token = await getToken();
    setToken(token);
  };
  useEffect(() => {
    getTokenFromInstance();
  }, []);

  const instagramShareAvailable = dishData.detail.top_dish_variant_share_meta !== null 
    && String(dishData.detail.top_dish_variant_share_meta).trim() !== '';
  
  const growthDataAvailable = dishData.detail.meta_post_count_variation !== null
    && String(dishData.detail.meta_post_count_variation).trim() !== '';

  const countryMapping = {
    IN: 'India',
    US: 'United States of America',
    BR: 'Brazil',
    ID: 'Indonesia',
  };

  const countries = dishData.countries.map(code => countryMapping[code] || code).join(', ');

  let text = `<b>${dishData.trendingDish}</b> is a variant of ${dishData.topDish} for ${dishData.brand} in ${countries}. It has a trend index of ${dishData.detail.trend_index} this month, ${dishData.detail.trend_index_variation > 0 ? 'an increase of' : 'a decrease of'} ${Math.abs(dishData.detail.trend_index_variation)} vs. last month.`;

  if (instagramShareAvailable) {
    text += ` With an Instagram share of ${dishData.detail.top_dish_variant_share_meta}%,`;

    if (growthDataAvailable) {
      text += ` it has ${dishData.detail.meta_post_count_variation > 0 ? 'increased by' : 'decreased by'} ${Math.abs(dishData.detail.meta_post_count_variation)}% in social discussion in the past month.`;
    } else {
      text += ' there is no growth data for this dish variant available yet.';
    }

  } else {
    if (growthDataAvailable) {
      text += ` There is an ${dishData.detail.meta_post_count_variation > 0 ? 'increase' : 'decrease'} by ${Math.abs(dishData.detail.meta_post_count_variation)}% in social discussion in the past month.`;
    } else {
      text += ' There is no Instagram data available for this dish variant.';
    }
  }

  return (
    <>
      <div ref={ref}  id='Trend Summary'>
        <DetailTitle title={'Trend Summary'}/>
      </div>
      <div dangerouslySetInnerHTML={{ __html: text }} className='deepDiveText'/>
      <div>
      <img
                      src={
                        fileprefix +
                        (dishData.detail.top_dish_variant_image_url_lg
                          ? `${dishData.detail.top_dish_variant_image_url_lg}&token=${`${newToken}`}`
                          : NoImage)
                      }
                      className='deepDiveImg'
                      alt={dishData.detail.top_dish_variant}
                      //key={index}
                    />
        {/* <img className='deepDiveImg' src={NoImage} /> */}
        </div>
    </>
  );
});
TrendSummary.displayName = 'TrendSummary';
export default TrendSummary;